import React, { useState, useRef, useEffect } from "react";
import ActionBar from "../../common/ActionBar";
import { Container, HyperLink, StyledTitle, TableWrapper } from "./Styles";
import _ from "lodash";
import CustomTable from "../../common/CustomTable/CustomTable";
import { Header } from "../Header/Header";
import labels from "../../../helpers/labels.json";
import CaseService from "../../../services/CaseService";
import { useSnackbar } from "notistack";
import queryString from "query-string";
import {
  getErrorMessage,
  parseTimeStamp,
  renderFeeKind,
} from "../../../helpers/functions";
import { pending_action_msg } from "../../../helpers/constants";
import useLoader from "../../../hooks/useLoader";
import moment from "moment";
import { navigate } from "@reach/router";
import useNotification from "../../../hooks/useNotification";

async function getPendingActions(query = "") {
  try {
    const response = await CaseService.getPendingActions(query);
    return { ...response };
  } catch (error) {
    throw error;
  }
}

const Index = () => {
  const breadcrumbs = [
    _.startCase(labels.my_tasks),
    _.startCase(labels.pending_actions),
  ];

  const { enqueueSnackbar } = useSnackbar();
  const { setLoader } = useLoader();
  const placeholderText = "Search Actions";
  const [state, setState] = useState({});
  const MTRef = useRef();
  const { triggerNotify, setTriggerNotify } = useNotification(); // hooks to refresh the page

  const sendRemaider = async ({ kind, entity }) => {
    setLoader({ state: true, message: "Sending Reminder..." });
    try {
      const response = await CaseService.sendRemainder(kind, entity.id);
      if (response?.message) {
        enqueueSnackbar(response?.message, {
          variant: "success",
        });
      }
    } catch (error) {
      enqueueSnackbar(getErrorMessage(error), {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
    }
  };

  const pendingAction = (rowData, kind) => {
    console.log(rowData);
    if (
      kind === "caseManagerSummaryUpload" ||
      kind === "caseManagerAgreementUpload"
    ) {
      navigate("/dashboard/uploads");
    } else if (
      kind === "caseManagerReviewCounterClaim" ||
      kind === "mediatorReviewCounterClaim"
    ) {
      navigate(`/dashboard/mediation/${rowData?.entity?.id}`);
    } else {
      sendRemaider(rowData);
    }
  };
  const columns = [
    {
      field: "entityId",
      title: labels.case_id,
      sorting: true,
      cellStyle: {
        width: "10%",
        paddingLeft: 20,
      },
      headerStyle: {
        width: "10%",
        paddingLeft: 20,
      },
      render: (rowData) => (
        <HyperLink
          onClick={() =>
            navigate(
              `/dashboard/${rowData?.entity?.resolutionKind}/${rowData?.entity?.id}`
            )
          }
        >
          {rowData?.entity?.id}
        </HyperLink>
      ),
    },
    {
      field: "case_title",
      title: labels.case_title,
      sorting: false,
      cellStyle: {
        width: "15%",
      },
      headerStyle: {
        width: "20%",
      },
      render: (rowData) => <StyledTitle>{rowData?.entity?.title}</StyledTitle>,
    },
    {
      field: "claim_party",
      title: labels.claim_party,
      sorting: true,
      cellStyle: {
        width: "15%",
      },
      headerStyle: {
        width: "15%",
      },
      render: (rowData) => <>{rowData?.entity?.claimantParty?.name}</>,
    },
    {
      field: "counter_party",
      title: labels.counter_party,
      sorting: true,
      cellStyle: {
        width: "15%",
      },
      headerStyle: {
        width: "15%",
      },
      render: (rowData) => (
        <>
          {rowData?.entity?.respondentParty?.name ? (
            rowData.entity?.respondentParty.name
          ) : rowData?.entity?.respondentName ? (
            rowData.entity?.respondentName
          ) : rowData?.entity?.respondentParties?.length ? (
            rowData?.entity?.respondentParties[0]?.name
          ) : (
            <div style={{ marginLeft: 35 }}>-</div>
          )}
        </>
      ),
    },
    {
      field: "pending_action",
      title: _.startCase(labels.pending_actions).substr(
        0,
        labels.pending_actions.length - 1
      ),
      sorting: false,
      cellStyle: {
        width: "15%",
      },
      headerStyle: {
        width: "15%",
      },
      render: (rowData) => (
        <>{renderFeeKind(rowData?.message, rowData?.meta)}</>
      ),
    },
    {
      field: "created_at",
      title: labels.target_date,
      sorting: true,
      cellStyle: {
        width: "15%",
      },
      headerStyle: {
        width: "15%",
      },
      render: (rowData) => (
        <>
          {pending_action_msg[rowData?.kind]?.isPayable
            ? moment(
                rowData.submittedOn
                  ? rowData.submittedOn
                  : parseTimeStamp(rowData.created_at)
              )
                .add(process.env.REACT_APP_BASE_TARGET_DATE, "days")
                .format("DD/MM/YYYY")
            : ""}
        </>
      ),
    },
    {
      field: "actions",
      title: labels.actions,
      sorting: false,
      cellStyle: {
        width: "20%",
      },
      headerStyle: {
        width: "20%",
      },
      render: (rowData) => (
        <HyperLink onClick={() => pendingAction(rowData, rowData?.kind)}>
          {pending_action_msg[rowData?.kind]?.linkText}
        </HyperLink>
      ),
    },
  ];

  const data = (query) =>
    new Promise((resolve) => {
      let params = {
        page: query.page + 1,
        perPage: 10,
      };
      if (query.orderBy?.field) {
        params.sort = query.orderBy.field;
        params.sortDirection = query.orderDirection;
      }
      let stringParams = "";
      if (!_.isEmpty(params)) {
        stringParams = `?${queryString.stringify(params)}`;
      }
      getPendingActions(stringParams)
        .then(async (result) => {
          if (result) {
            resolve({
              data: result?.activities?.data,
              page: result?.activities?.page - 1,
              total: result?.activities?.total,
            });
            setState(result?.activities);
          }
        })
        .catch((error) => {
          enqueueSnackbar(getErrorMessage(error), {
            variant: "error",
          });
          setState({ data: [], lastPage: 1, page: 1, perPage: 10, total: 0 });
          resolve({
            data: [],
            page: 0,
            total: 0,
          });
        });
    });

  useEffect(() => {
    if (triggerNotify) {
      MTRef.current.onSearchChange("");
      setTriggerNotify(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerNotify]);

  return (
    <Header
      showSidebar
      selectedItem={labels.my_tasks}
      selectedSubItem={labels.pending_actions}
    >
      <ActionBar {...{ breadcrumbs }} />
      <Container className="PendingActions">
        <TableWrapper>
          <CustomTable
            hidePagination={state.lastPage <= 1}
            pageSize={state?.data?.length ? state?.data?.length : 10}
            pluralTitle="Pending Actions"
            singularTitle=""
            {...{
              columns,
              data,
              placeholderText,
              MTRef,
            }}
            noToolbar
            //hideFilterBar
            state={state}
          />
        </TableWrapper>
      </Container>
    </Header>
  );
};

export default Index;
