const { REACT_APP_BASE_API_URL } = process.env;

const ApiConfig = {
  baseUrl: REACT_APP_BASE_API_URL,
  urls: {
    auth: {
      login: "case-managers/sessions",
      profile: "me",
    },
    party: {
      get: "casemanager/parties",
      approve: "casemanager/verify-party",
      index: "casemanager/parties",
      stats: "casemanager/users/parties-stats",
      profileParties: "casemanager/users/parties",
      partyStats: "casemanager/party-stats",
      resupdate: "casemanager/updateRespondentParties",
    },
    case: {
      caseIndex: "cases",
      case: "casemanager",
      index: "casemanager/cases",
      stats: "stats",
      caseStats: "casemanager/case-stats",
      uploads: "casemanager/uploads",
      bulkUpload: "casemanager/uploads/bulkCaseUpload",
      saveSummary: "casemanager/summary",
      uploadAgreement: "negotiationAgreement",
      caseAgreementUpload: "casemanager/uploads/case",
      uploadStats: "casemanager/stats",
      case_document_uploads: "casemanager/case-document-uploads",
      mediation: "casemanager/mediation",
      negotiation: "casemanager/negotiation",
      uploadCase: "casemanager/uploads/case",
      getCaseDocuments: "casemanager/uploads",
      pendingAction: "casemanager/activities",
      offlinePayment: "payment-capture-offline",
      comments: "/comments",
      paymentRefund: "/casemanager/payments/refund",
      orders: "/orders",
      notificationCount: "/casemanager/notification-count",
      draft: "/cases/drafts",
      selectDraft: "/selects/drafts",
      select: "/selects/case",
      notes: "/notes",
      uploadDocCase: "uploads/case",
      transcripts: "/transcripts",
      reOpenCase: "/reOpenCase",
    },
    payment: {
      payments: "/payments",
    },
    meeting: {
      meetings: "/meetingJoin",
      guestmeetingUrl: "guestMeetingUrl",
      meetingRecordings: "/meetingRecordings",
      genericMeetingJoin: "/genericMeetingJoin",
      genericMeetingRecordings: "/genericMeetingRecordings",
      genericMeetingUrl: "/genericMeetingUrl",
      genericMeeting: "/genericMeeting",
    },
    systemConfig: "system-configs",
    systemConfigAgreement: "casemanager/system-configs",
    s3: "s3-url",
    notificationCount: "/notification-count",
    user: {
      index: "casemanager/users",
      update: "casemanager/update-user",
    },
  },
};

export default ApiConfig;
