import API from "../api/manager";
import config from "../api/config";

const index = (query) => {
  return API({
    method: "GET",
    url: config.urls.case.index + query,
  });
};

const stats = () => {
  return API({
    method: "GET",
    url: config.urls.case.stats,
  });
};

const caseStats = (param) => {
  return API({
    method: "GET",
    url: config.urls.case.caseStats + param,
  });
};

const uploads = (query) => {
  return API({
    method: "GET",
    url: config.urls.case.uploads + query,
  });
};

const bulkUpload = (query) => {
  return API({
    method: "GET",
    url: config.urls.case.bulkUpload + query,
  });
};

const saveSummary = (data, id, action) => {
  return API({
    method: "POST",
    url: config.urls.case.saveSummary + `/${id}/${action}`,
    data,
  });
};

const uploadAgreement = (data, caseId) => {
  return API({
    method: "POST",
    url: `${config.urls.case.caseAgreementUpload}/${caseId}/${config.urls.case.uploadAgreement}`,
    data,
  });
};
const updateAgreement = (data, caseId, docId) => {
  return API({
    method: "PUT",
    url: `${config.urls.case.caseAgreementUpload}/${caseId}/${config.urls.case.uploadAgreement}/${docId}`,
    data,
  });
};

const offlinePayment = (data, caseId) => {
  return API({
    method: "POST",
    url: `${config.urls.case.offlinePayment}/${caseId}`,
    data,
  });
};

const caseDocumentListing = (query) => {
  return API({
    method: "GET",
    url: config.urls.case.case_document_uploads + query,
  });
};

const uploadStats = () => {
  return API({
    method: "GET",
    url: config.urls.case.uploadStats,
  });
};

const caseAddDocument = (data, id) => {
  return API({
    method: "POST",
    url: config.urls.case.uploadCase + `/${id}/dealAgreement`,
    data,
  });
};

const negotiations = (id) => {
  return API({
    method: "GET",
    url: config.urls.case.case + `/${id}/negotiations`,
  });
};

const getCase = (id) => {
  return API({
    method: "GET",
    url: config.urls.case.index + `/${id}`,
  });
};

const downloadCaseAgreement = (id) => {
  return API({
    method: "GET",
    url: config.urls.case.uploadCase + `/${id}/negotiationAgreement`,
  });
};

const getcaseDocuments = (id, query) => {
  return API({
    method: "GET",
    url:
      config.urls.case.getCaseDocuments +
      `/case/${id}/dealAgreement` +
      `${query}`,
  });
};

const deleteDocument = (id, docId) => {
  return API({
    method: "DELETE",
    url: config.urls.case.getCaseDocuments + `/case/${id}/${docId}`,
  });
};

const getPendingActions = (query) => {
  return API({
    method: "GET",
    url: config.urls.case.pendingAction + query,
  });
};

const sendRemainder = (kind, id) => {
  return API({
    method: "POST",
    url: config.urls.case.pendingAction + `/${id}/${kind}`,
  });
};

const assignMediator = (data, managerId) => {
  return API({
    method: "PUT",
    url: config.urls.case.assignCase + `/${data?.id}/${managerId}`,
    data: {},
  });
};

const refundPayment = (data) => {
  return API({
    method: "POST",
    url: config.urls.case.paymentRefund,
    data,
  });
};

const updateCase = (caseId, data) => {
  return API({
    method: "PUT",
    url: config.urls.case.index + `/${caseId}`,
    data,
  });
};

const mediations = (id, query) => {
  return API({
    method: "GET",
    url: `${config.urls.case.comments}/${id}${query}`,
  });
};

const sendComment = (id, data) => {
  return API({
    method: "POST",
    url: `${config.urls.case.comments}/${id}`,
    data,
  });
};

const updateComment = (id, data, commentId) => {
  return API({
    method: "PUT",
    url: `${config.urls.case.comments}/${id}/${commentId}`,
    data,
  });
};

const deleteComment = (id, commentId) => {
  return API({
    method: "DELETE",
    url: `${config.urls.case.comments}/${id}/${commentId}`,
    data: {},
  });
};

const mediationSuccess = (id, data) => {
  return API({
    method: "POST",
    url: `${config.urls.case.mediation}/${id}/success`,
    data: {},
  });
};

const quitMediation = (id, action = "closedByCaseManager") => {
  return API({
    method: "DELETE",
    url: `${config.urls.case.mediation}/${id}/${action}`,
    data: {},
  });
};

const quitNegotiation = (id, action = "closedByCaseManager") => {
  return API({
    method: "DELETE",
    url: `${config.urls.case.negotiation}/${id}/${action}`,
    data: {},
  });
};

const removeMediator = (id) => {
  return API({
    method: "DELETE",
    url: `${config.urls.case.mediation}/${id}/remove-mediator`,
    data: {},
  });
};

const getPayments = (id) => {
  return API({
    method: "GET",
    url: config.urls.case.index + `/${id}/payments`,
  });
};
const getOrders = (query) => {
  return API({ method: "GET", url: config.urls.case.orders + query });
};

const getNotificationCount = () => {
  return API({ method: "GET", url: config.urls.case.notificationCount });
};

const clearNotification = () => {
  return API({ method: "POST", url: config.urls.notificationCount, data: {} });
};

const deleteDraftCase = (id) => {
  return API({
    method: "DELETE",
    url: config.urls.case.draft + `/${id}`,
    data: {},
  });
};

const deleteSelectedCases = () => {
  return API({
    method: "DELETE",
    url: config.urls.case.selectDraft,
    data: {},
  });
};

const addAllCases = (query) => {
  return API({
    method: "POST",
    url: config.urls.case.select + query,
    data: {},
  });
};

const removeAllCases = (query) => {
  return API({
    method: "DELETE",
    url: config.urls.case.select + query,
    data: {},
  });
};

const selectCaseItem = (id) => {
  return API({
    method: "POST",
    url: config.urls.case.select + `/${id}`,
    data: {},
  });
};

const getMeeting = (id) => {
  return API({
    method: "GET",
    url: config.urls.meeting.meetings + `/${id}`,
  });
};

const removeCaseItem = (id) => {
  return API({
    method: "DELETE",
    url: config.urls.case.select + `/${id}`,
    data: {},
  });
};

const getPartyListByCaseId = (caseId, param) => {
  return API({
    method: "GET",
    url: config.urls.case.caseIndex + `/${caseId}/parties${param}`,
  });
};

const addNotes = (data, caseId) => {
  return API({
    method: "POST",
    url: `${config.urls.case.notes}/${caseId}`,
    data,
  });
};

const getNotes = (caseId, query) => {
  return API({
    method: "GET",
    url: `${config.urls.case.notes}/${caseId}${query}`,
  });
};

const updateCaseEmail = (caseId, data) => {
  return API({
    method: "PUT",
    url: config.urls.party.resupdate + `/${caseId}`,
    data,
  });
};

const getGuestUserMeeting = (caseId) => {
  return API({
    method: "GET",
    url: `${config.urls.meeting.guestmeetingUrl}/${caseId}`,
  });
};

const getMeetingVideo = (id, query) => {
  return API({
    method: "GET",
    url: `${config.urls.meeting.meetingRecordings}/${id}${query}`,
  });
};

const changeDocType = (data, caseId, docId) => {
  return API({
    method: "PUT",
    url: config.urls.case.uploadDocCase + `/${caseId}/${docId}`,
    data,
  });
};

const addTranscript = (data) => {
  return API({
    method: "POST",
    url: `${config.urls.case.transcripts}`,
    data,
  });
};

const updateTranscript = (data, id) => {
  return API({
    method: "PUT",
    url: config.urls.case.transcripts + `/${id}`,
    data,
  });
};

const deleteTranscriptDoc = (id) => {
  return API({
    method: "DELETE",
    url: config.urls.case.transcripts + `/${id}`,
    data: {},
  });
};

const nextHearingDate = (data, id) => {
  return API({
    method: "PUT",
    url: config.urls.case.index + `/${id}`,
    data,
  });
};

const updateCaseReopen = (id, data) => {
  return API({
    method: "PUT",
    url: `${config.urls.case.reOpenCase}/${id}`,
    data
  });
};

const getRefundHistory = (query) => {
  return API({ method: "GET", url: config.urls.payment.payments + query });
};

const joinMeeting = caseManagerId => {
  return API({
    method: "GET",
    url: `${config.urls.meeting.genericMeetingJoin}/${caseManagerId}`
  });
};

const getRecordings = query => {
  return API({
    method: "GET",
    url: `${config.urls.meeting.genericMeetingRecordings}${query}`
  });
};

const getMeetingLink = () => {
  return API({
    method: "GET",
    url: `${config.urls.meeting.genericMeetingUrl}`
  });
};

const getGenericMeeting = (data, code) => {
  return API({
    method: "POST",
    url: `${config.urls.meeting.genericMeeting}/${code}`,
    data,
  }, false);
};

export default {
  index,
  stats,
  uploads,
  saveSummary,
  uploadAgreement,
  uploadStats,
  caseDocumentListing,
  caseAddDocument,
  negotiations,
  getCase,
  getMeeting,
  downloadCaseAgreement,
  getcaseDocuments,
  caseStats,
  getPendingActions,
  sendRemainder,
  offlinePayment,
  updateAgreement,
  assignMediator,
  updateCase,
  mediations,
  sendComment,
  mediationSuccess,
  quitMediation,
  removeMediator,
  getPayments,
  deleteDocument,
  refundPayment,
  bulkUpload,
  getOrders,
  getNotificationCount,
  clearNotification,
  quitNegotiation,
  deleteDraftCase,
  deleteSelectedCases,
  addAllCases,
  removeAllCases,
  selectCaseItem,
  removeCaseItem,
  updateComment,
  deleteComment,
  getPartyListByCaseId,
  addNotes,
  getNotes,
  updateCaseEmail,
  getGuestUserMeeting,
  getMeetingVideo,
  changeDocType,
  addTranscript,
  updateTranscript,
  deleteTranscriptDoc,
  nextHearingDate,
  updateCaseReopen,
  getRefundHistory,
  joinMeeting,
  getRecordings,
  getMeetingLink,
  getGenericMeeting,
};
