import React, { useEffect, useState, useRef } from "react";
import ActionBar from "../../common/ActionBar";
import styled from "styled-components";
import _ from "lodash";
import { navigate } from "@reach/router";
import theme from "../../../assets/theme";
import COLORS from "../../../assets/Colors";
import { PrimaryCTAButton } from "../../common/Buttons";
import PartyService from "../../../services/PartyService";
import { getErrorMessage } from "../../../helpers/functions";
import { useSnackbar } from "notistack";
import useLoader from "../../../hooks/useLoader";
import labels from "../../../helpers/labels.json";
import { documentLabels, states } from "../../../helpers/constants";
import AgentListModal from "../../common/AgentListModal/AgentListDrawer";

function getAgentName(agents, isPrimary) {
  const agentList = isPrimary
    ? agents?.filter((el) => el.role === "primary")
    : agents?.filter((el) => el.role !== "primary");
  if (agentList?.length) {
    let final = [];
    for (const agent of agentList) {
      if (agent?.user?.name) {
        final.push(agent.user.name);
      }
    }
    return final.join(", ");
  }
}

const Index = ({ partyId, isFromListing = false }) => {
  const verifyBreadCrum = [
    _.startCase(labels.my_tasks),
    _.startCase(labels.verify),
    _.startCase(labels.party_details),
  ];

  const partyDetailBreadCrum = [
    _.startCase(labels.parties),
    _.startCase(labels.party_details),
  ];
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [party, setParty] = useState({});
  const { enqueueSnackbar } = useSnackbar();
  const { setLoader } = useLoader();
  const [primaryAgentList, setPrimaryAgentList] = useState([]);
  const [agentList, setAgentList] = useState([]);
  const MTRef = useRef();
  const [opendrawer, setOpenDrawer] = useState(false);
  const onBreadcrumbClick = (selected) => {
    switch (_.snakeCase(selected)) {
      case labels.my_tasks:
        navigate("/dashboard");
        break;
      case labels.parties:
        navigate("/dashboard/parties");
        break;
      case labels.verify:
        navigate("/dashboard/verify-parties");
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (isFromListing) {
      setBreadcrumbs(partyDetailBreadCrum);
    } else {
      setBreadcrumbs(verifyBreadCrum);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function getParty() {
      try {
        setLoader({ state: true, message: "Fetching party..." });
        const response = await PartyService.getParty(partyId);
        setParty(response);
        setPrimaryAgentList(getAgentName(response?.agents, true));
        setAgentList(getAgentName(response?.agents, false));
      } catch (error) {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, {
          variant: "error",
        });
      } finally {
        setLoader({ state: false });
      }
    }
    if (partyId) {
      getParty();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partyId]);

  const handleApprove = async () => {
    try {
      setLoader({ state: true, message: "Verifying party..." });
      const response = await PartyService.approveParty(partyId);
      if (response) {
        enqueueSnackbar(_.startCase(response.message), {
          variant: "success",
        });
        navigate("/dashboard/verify-parties");
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
    }
  };

  if (!_.isEmpty(party))
    return (
      <>
        <ActionBar {...{ breadcrumbs, onBreadcrumbClick }} />
        <Container className="CreateCase">
          <Wrapper>
            <Row>
              <ColumnSm>
                <H1>{labels.party_type}</H1>
                <H2>{_.capitalize(party?.kind)}</H2>
              </ColumnSm>
              <ColumnSm>
                <H1>{labels.party_id}</H1>
                <H2>{party?.id}</H2>
              </ColumnSm>
              <ColumnSm>
                <H1>{labels.party_name}</H1>
                <H2>{party?.name}</H2>
              </ColumnSm>
              <ColumnSm>
                <H1>{labels.document_type}</H1>
                <H2>
                  {party.documentKind === "voter"
                    ? "VOTER ID"
                    : party.documentKind === "aadhar"
                    ? "AADHAAR"
                    : _.upperCase(party.documentKind)}
                </H2>
              </ColumnSm>
              <ColumnSm>
                <H1>
                  {
                    documentLabels[
                      party.documentKind === "aadhar"
                        ? "aadhaar"
                        : party.documentKind
                    ]
                  }
                </H1>
                <H2>{party.documentNumber}</H2>
              </ColumnSm>
            </Row>
          </Wrapper>
          <Wrapper>
            <H1>{labels.communication_address}</H1>

            <Row style={{ width: 750 }}>
              <table>
                <tr>
                  <th align="left">
                    <H1>{labels.house_no}</H1>
                  </th>
                  <th align="left">
                    <H1>{labels.locality}</H1>
                  </th>
                  <th align="left">
                    <H1>{labels.landmark}</H1>
                  </th>
                  <th align="left">
                    <H1>{labels.city}</H1>
                  </th>
                  <th align="left">
                    <H1>{labels.state}</H1>
                  </th>
                  <th align="left">
                    <H1>{labels.pin}</H1>
                  </th>
                </tr>
                <tr>
                  <td valign="top">
                    <H2>{party?.houseNumber}</H2>
                  </td>
                  <td valign="top">
                    <H2>{party?.locality}</H2>
                  </td>
                  <td valign="top">
                    <H2>{party?.landmark}</H2>
                  </td>
                  <td valign="top">
                    <H2>{party?.city}</H2>
                  </td>
                  <td valign="top">
                    <H2>
                      {states.find((state) => state.value === party?.state)
                        .label || party?.state}
                    </H2>
                  </td>
                  <td valign="top">
                    <H2>{party?.pin}</H2>
                  </td>
                </tr>
              </table>
            </Row>
          </Wrapper>
          {party?.billingHouseNumber &&
          party?.billingLocality &&
          party.billingLandmark &&
          party.billingCity &&
          party.billingState &&
          party.billingPin ? (
            <Wrapper>
              <H1>{labels.billing_address}</H1>

              <Row style={{ width: 750 }}>
                <table>
                  <tr>
                    <th align="left">
                      <H1>{labels.house_no}</H1>
                    </th>
                    <th align="left">
                      <H1>{labels.locality}</H1>
                    </th>
                    <th align="left">
                      <H1>{labels.landmark}</H1>
                    </th>
                    <th align="left">
                      <H1>{labels.city}</H1>
                    </th>
                    <th align="left">
                      <H1>{labels.state}</H1>
                    </th>
                    <th align="left">
                      <H1>{labels.pin}</H1>
                    </th>
                  </tr>
                  <tr>
                    <td valign="top">
                      <H2>{party?.billingHouseNumber}</H2>
                    </td>
                    <td valign="top">
                      <H2>{party?.billingLocality}</H2>
                    </td>
                    <td valign="top">
                      <H2>{party?.billingLandmark}</H2>
                    </td>
                    <td valign="top">
                      <H2>{party?.billingCity}</H2>
                    </td>
                    <td valign="top">
                      <H2>
                        {states.find(
                          (billingState) =>
                            billingState.value === party?.billingState
                        ).label || party?.billingState}
                      </H2>
                    </td>
                    <td valign="top">
                      <H2>{party?.billingPin}</H2>
                    </td>
                  </tr>
                </table>
              </Row>
            </Wrapper>
          ) : (
            ""
          )}
          <Wrapper>
            <Row style={{ width: 300 }}>
              <ColumnSm>
                <H1 style={{ marginBottom: 5 }}>{labels.documents}</H1>
                <HyperLink
                  onClick={() => window.open(party.documentUrl)}
                  style={{ marginBottom: 15 }}
                >
                  {
                    documentLabels[
                      party.documentKind === "aadhar"
                        ? "aadhaar"
                        : party.documentKind
                    ]
                  }
                </HyperLink>
                {party.gstCertUrl ? (
                  <HyperLink onClick={() => window.open(party.gstCertUrl)}>
                    GST Certificate
                  </HyperLink>
                ) : (
                  ""
                )}
                {party.authorizationLetterUrl ? (
                  <HyperLink
                    onClick={() => window.open(party.authorizationLetterUrl)}
                    style={{ marginBottom: 15 }}
                  >
                    Authorization Letter
                  </HyperLink>
                ) : (
                  ""
                )}
              </ColumnSm>
              {party.gstNumber && (
                <ColumnSm style={{ marginBottom: "auto" }}>
                  <H1 style={{ marginBottom: 5 }}>{labels.gstNumber}</H1>
                  <H2>{party.gstNumber}</H2>
                </ColumnSm>
              )}
            </Row>
          </Wrapper>

          <Wrapper>
            <Row style={{ width: 300 }}>
              {primaryAgentList?.length > 0 && (
                <ColumnSm>
                  <H1>
                    {labels.primary_agents}
                    {party?.agents?.length > 0 ? (
                      <HyperLink
                        style={{ display: "table" }}
                        onClick={() =>
                          setOpenDrawer((a) => ({ ...a, state: true }))
                        }
                      >
                        Agent Details
                      </HyperLink>
                    ) : null}
                  </H1>

                  <H2>{primaryAgentList}</H2>
                </ColumnSm>
              )}
              {agentList?.length > 0 && (
                <ColumnSm>
                  <H1>{labels.agents}</H1>
                  <H2>{agentList}</H2>
                </ColumnSm>
              )}
            </Row>
          </Wrapper>

          <Wrapper>
            {party?.status === "underReview" && (
              <ButtonWrapper>
                <PrimaryCTAButton onClick={handleApprove}>
                  {labels.verify_party}
                </PrimaryCTAButton>
              </ButtonWrapper>
            )}
          </Wrapper>
        </Container>
        <AgentListModal
          {...{
            opendrawer,
            setOpenDrawer,
            MTRef,
            partyList: [{ party }],
            displayAuthUrl: true,
          }}
        />
      </>
    );
  else return null;
};

export default Index;

export const Container = styled.div`
  padding: 34px 43px;
  display: flex;
  flex-direction: column;
`;
export const Row = styled.div`
  width: 600px;
  max-width: 1000px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  & th,
  td {
    padding-right: 15px;
    width: calc(100% / 6 - 60px);
  }
`;
export const H1 = styled.h2`
  font-family: ${theme.fonts.primaryFontRegular};
  color: ${COLORS.INPUT_LABEL};
  font-size: 10px;
  line-height: 1.7;
  margin-bottom: 2px;
  // text-transform: uppercase;
`;
export const H2 = styled.h2`
  font-family: ${theme.fonts.primaryFontBold};
  color: ${COLORS.COLOR_DARK};
  font-size: 14px;
`;
export const Heading = styled.div`
  font-family: ${theme.fonts.primaryFontRegular};
  color: ${COLORS.BORDER_GREY};
  font-size: 10px;
`;
export const ButtonWrapper = styled.div`
  width: 216px;
`;
export const Wrapper = styled.div`
  &:nth-child(1) {
    margin-bottom: 30px;
  }
  &:nth-child(2) {
    margin-bottom: 30px;
  }
  &:nth-child(3) {
    margin-bottom: 18px;
  }
  &:nth-child(4) {
    margin-bottom: 50px;
    & h2 {
      margin-bottom: 5px;
    }
    & div {
      margin-bottom: 15px;
    }
  }
`;
export const ColumnSm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-right: 20px;
`;
export const HyperLink = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 14px;
  color: ${COLORS.BTN_GREEN};
  text-decoration: underline;
  cursor: pointer;
`;
