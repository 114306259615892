import React, { useState } from "react";
import {
  CustomInputField,
  CustomDatePicker,
  CustomMobileInput,
  CustomTimePicker,
} from "../FormInputs";
import theme from "../../../assets/theme";
import {
  FormLabel,
  IconButton,
  InputAdornment,
  TextareaAutosize,
} from "@material-ui/core";
import CustomSelect from "../CustomSelect/CustomSelect";
import COLORS from "../../../assets/Colors";
import styled, { css } from "styled-components";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import { get } from "lodash";
import { Visibility, VisibilityOff } from "@material-ui/icons";

const filter = createFilterOptions();

export default function FormField({
  touched,
  errors,
  input,
  values,
  handleChange,
  handleBlur,
  menuItems,
  setFieldValue,
  white = false,
  showAllInitial = false,
  selectedIndex = "label",
  alias,
  multiline,
  row = 4,
  disabled,
  maxLength = 1000,
  style = {},
  minDate,
  maxDate,
  openTo,
  views,
  format = "DD/MM/YYYY",
  placeholderText,
  selectedItemChanged = () => {},
}) {
  const { name, label, type, placeholder, required } = input;
  const [visibility, setVisibility] = useState(false);
  const [searchInput, setSearchInput] = useState();
  const createNewOption = (options, params) => {
    const filtered = filter(options, params);
    if (params.inputValue !== "") {
      let temp = {};
      temp["inputValue"] = params.inputValue;
      temp[selectedIndex] = `Add "${params.inputValue}"`;
      filtered.push(temp);
    }
    return filtered;
  };

  const calRenderOption = (option) => {
    // Value selected with enter, right from the input
    if (typeof option === "string") {
      return option;
    }
    // Add "xxx" option created dynamically
    if (option.inputValue) {
      return option.inputValue;
    }
    // Regular option
    return option[selectedIndex];
  };
  return (
    <div className="form-fields" style={{ width: "100%", paddingTop: 5 }}>
      <FormLabel
        error={errors[name] && touched[name]}
        style={{
          fontFamily: theme.fonts.primaryFontSemiBold,
          fontSize: 10,
          color: COLORS.INPUT_LABEL,
        }}
      >
        {label}
        {required && <span className="required-star"> *</span>}
      </FormLabel>
      {type === "autoselect" ? (
        <Autocomplete
          freeSolo
          disableClearable
          options={showAllInitial || searchInput?.trim() ? menuItems : []}
          name={name}
          value={get(values, name)}
          onChange={(_, v) => {
            v[selectedIndex] = v["inputValue"]
              ? v["inputValue"]
              : v[selectedIndex];
            selectedItemChanged(v);
          }}
          clearOnBlur
          filterOptions={(options, params) => createNewOption(options, params)}
          getOptionLabel={(option) => calRenderOption(option)}
          renderOption={(option) => (
            <React.Fragment>
              {option[selectedIndex]} {option[alias] && `(${option[alias]})`}
            </React.Fragment>
          )}
          onBlur={handleBlur}
          id={name}
          style={{ marginTop: 6 }}
          disabled={disabled}
          renderInput={(params) => (
            <CustomInputField
              {...params}
              error={errors[name] && touched[name]}
              helperText={touched[name] && errors[name]}
              onChange={(e) => {
                setSearchInput(e.target.value);
              }}
              onBlur={handleBlur}
              className={`input-no-padding ${white ? "input-white" : ""}`}
              variant="outlined"
              InputProps={{ ...params.InputProps, type: "search" }}
              placeholder={placeholder}
            />
          )}
        />
      ) : type === "select" ? (
        <CustomSelect
          white={white}
          id={name}
          menuItemValues={menuItems}
          name={name}
          value={values[name]}
          onChange={handleChange}
          error={errors[name] && touched[name]}
          onBlur={handleBlur}
          style={{ marginTop: 6 }}
        />
      ) : type === "date" ? (
        <CustomDatePicker
          className={white ? "input-white" : ""}
          inputVariant="outlined"
          openTo={openTo}
          minDate={minDate}
          maxDate={maxDate}
          format={format}
          placeholder={placeholderText}
          views={views}
          value={values[name]}
          onChange={(e) => setFieldValue(name, e)}
          error={errors[name] && touched[name]}
          helperText={touched[name] && errors[name]}
          style={{ marginTop: 6 }}
        />
      ) : type === "time" ? (
        <CustomTimePicker
          className={white ? "input-white" : ""}
          clearable
          inputVariant="outlined"
          ampm={true}
          placeholder={placeholderText}
          error={errors[name] && touched[name]}
          helperText={touched[name] && errors[name]}
          value={values[name]}
          onChange={(e) => setFieldValue(name, e)}
          style={{ marginTop: 6 }}
        />
      ) : name === "mobile" ? (
        <CustomMobileInput
          className={white ? "input-white" : ""}
          defaultCountry={"in"}
          onChange={(e) => setFieldValue(name, e)}
          onBlur={handleBlur}
          autoFormat={false}
          style={{ marginTop: 6 }}
          value={values[name]}
          error={errors[name] && touched[name]}
          helperText={touched[name] && errors[name]}
          id={name}
          placeholder={placeholder}
          name={name}
          variant="outlined"
        />
      ) : type === "textarea" ? (
        <Relative>
          <CustomTextArea
            className={
              white && multiline
                ? "input-white input-height-auto"
                : white
                ? "input-white"
                : multiline
                ? "input-height-auto"
                : ""
            }
            disabled={disabled}
            rowsMin={5}
            rowsMax={5}
            autoComplete={"new-password"}
            type={type}
            value={values[name]}
            error={errors[name] && touched[name]}
            helperText={touched[name] && errors[name]}
            id={name}
            maxLength={1000}
            placeholder={placeholder}
            name={name}
            onChange={handleChange}
            onBlur={handleBlur}
            style={{ marginTop: 6, ...style }}
          />
          {maxLength > 0 && (
            <MaxLength>
              {maxLength - values[name]?.length} characters remaining
            </MaxLength>
          )}
          <HelperText>{touched[name] && errors[name]}</HelperText>
        </Relative>
      ) : (
        <CustomInputField
          className={
            white && multiline
              ? "input-white input-height-auto"
              : white
              ? "input-white"
              : multiline
              ? "input-height-auto"
              : ""
          }
          multiline={multiline}
          row={4}
          autoComplete={"new-password"}
          autoFocus={name === "fullname"}
          type={type === "password" ? (visibility ? "text" : type) : type}
          value={values[name]}
          error={errors[name] && touched[name]}
          helperText={touched[name] && errors[name]}
          id={name}
          placeholder={placeholder}
          name={name}
          variant="outlined"
          onChange={handleChange}
          onBlur={handleBlur}
          InputProps={{
            endAdornment: (
              <>
                {type === "password" ? (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setVisibility(!visibility)}
                      edge="end"
                    >
                      {visibility ? (
                        <Visibility style={{ fontSize: 18 }} />
                      ) : (
                        <VisibilityOff style={{ fontSize: 18 }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ) : null}
              </>
            ),
          }}
          style={{ marginTop: 6, marginBottom: 10 }}
        />
      )}
    </div>
  );
}

const Relative = styled.div`
  position: relative;
`;

const CustomTextArea = styled(TextareaAutosize)`
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  font-size: 16px;
  color: ${COLORS.COLOR_DARK} !important;
  font-family: ${theme.fonts.primaryFontSemiBold};
  /* min-height: 89px; */
  border-radius: 6px;
  border: solid 0.5px #e1e3ee;
  background-color: #ffffff;
  outline: none;
  padding: 15px;
  ${({ disabled }) =>
    !disabled
      ? css`
          :hover {
            border: solid 0.5px black;
          }
        `
      : css`
          background-color: ${COLORS.TABLE_GREY_CELL};
        `}

  ${({ error }) =>
    error &&
    css`
      border: solid 0.5px #f44336;
      :hover {
        border: solid 0.5px #f44336;
      }
    `}
`;

const MaxLength = styled.div`
  font-family: openSans-SemiBold, sans-serif;
  font-size: 10px;
  color: #acb1c2;
  text-align: right;
`;

const HelperText = styled.div`
  position: absolute;
  left: 3px;
  position: absolute;
  font-size: 10px;
  font-family: openSans-SemiBold, sans-serif;
  color: #f44336;
`;
