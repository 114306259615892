import React, { useEffect, useState } from "react";
import styled from "styled-components";
import COLORS from "../../../../assets/Colors";
import theme from "../../../../assets/theme";
import useLoader from "../../../../hooks/useLoader";
import { useSnackbar } from "notistack";
import { getErrorMessage } from "../../../../helpers/functions";
import labels from "../../../../helpers/labels.json";
import { CircularProgress, Modal } from "@material-ui/core";
import SearchBar from "../../../common/SearchBar";
import AddIcon from "@material-ui/icons/Add";
import AlertDialog from "../../../common/Alert";
import CaseService from "../../../../services/CaseService";
import useDebounce from "../../../../hooks/useDebounce";
import SystemService from "../../../../services/SystemService";
import queryString from "query-string";
import _ from "lodash";

export default function AssignCaseMediator({
  modal,
  setModal,
  caseDetails,
  onAssignSuccess,
  type = "Mediator",
}) {
  const { setLoader } = useLoader();
  const { enqueueSnackbar } = useSnackbar();
  const [inputText, setInputText] = useState("");
  const [cmList, setCmList] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedMediator, setSelectedMediator] = useState();
  const debounceValue = useDebounce(inputText, 500);

  useEffect(() => {
    if (modal) {
      setLoading(true);
      getCMList(debounceValue);
    } else {
      setCmList([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal, debounceValue]);

  async function getCMList(searchQuery) {
    try {
      let params = {
        page: 1,
        perPage: 1000,
        role: "mediator",
      };
      if (searchQuery) {
        params.query = searchQuery;
      }
      let stringParams = "";
      if (!_.isEmpty(params)) {
        stringParams = `?${queryString.stringify(params)}`;
      }
      const response = await SystemService.getProfiles(stringParams);
      if (response?.data) {
        setCmList(response?.data);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      throw error;
    } finally {
      setLoading(false);
    }
  }

  async function addMediator(caseDetails) {
    try {
      setLoader({ state: true, message: "Adding case manager..." });
      const payload = {
        requestedMediators: [
          ...caseDetails?.requestedMediators,
          ...[
            {
              email: selectedMediator?.email,
              name: selectedMediator?.name,
              mobile: selectedMediator?.mobile,
              avatarUrl: selectedMediator?.avatarUrl,
            },
          ],
        ],
      };
      const response = await CaseService.updateCase(caseDetails?.id, payload);
      if (response.message) {
        enqueueSnackbar(response.message, {
          variant: "success",
        });
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
      setOpen(false);
      onAssignSuccess();
    } finally {
      setLoader({ state: false });
      setOpen(false);
      onAssignSuccess();
    }
  }

  return (
    <Modal open={modal}>
      <DrawerContainer role="presentation">
        <HeadingContainer>
          <Heading>Add {type}</Heading>
          <CloseModal
            onClick={() => setModal(false)}
            src={require("../../../../assets/images/closeModal.svg")}
          />
        </HeadingContainer>
        <div className="ml40 mr40">
          <div>
            <SearchBar
              name={"caseManger"}
              placeHolderText={labels["search_mediator"]}
              backgroundColor="#f9f9f9"
              {...{ inputText, setInputText }}
              width="100%"
            />
          </div>
          {/* <!-- Container --> */}
          <div
            className="mt20 mb20 h300"
            style={{
              overflow: "auto",
            }}
          >
            {loading ? (
              <div className="loader">
                <CircularProgress />
              </div>
            ) : (
              <>
                {cmList?.length > 0 ? (
                  <>
                    {cmList?.map((cm) => (
                      <>
                        <div className="cm_row">
                          <div className="cm_name">
                            <div>{cm?.name}</div>
                            <div>({cm?.email})</div>
                          </div>
                          <div
                            className="add_circle"
                            onClick={() => {
                              setOpen(true);
                              setSelectedMediator(cm);
                            }}
                          >
                            <AddIcon className="add_icon" />
                          </div>
                        </div>
                      </>
                    ))}
                  </>
                ) : (
                  <div className="no_result">No {type}s found.</div>
                )}
              </>
            )}
          </div>
          <div>
            <AlertDialog
              isOpen={open}
              clickSecondarybtn={() => setOpen(false)}
              primaryBtnText={"Okay"}
              secondaryBtnText={"Cancel"}
              //desc={`Are you sure? You want to add ${selectedMediator?.name} with email ID (${selectedMediator?.email}) as the mediator?`}
              desc={
                <StyledTextSemi>
                  Are you sure? You want to add{" "}
                  <StyledText>{selectedMediator?.name}</StyledText> with email
                  ID <StyledText>{selectedMediator?.email}</StyledText> as the{" "}
                  {type}?
                </StyledTextSemi>
              }
              heading={`Add ${type}`}
              clickPrimaryBtn={() => addMediator(caseDetails)}
              descriptionTextStyle={{
                textAlign: "left",
                fontFamily: theme.fonts.primaryFontSemiBold,
              }}
            />
          </div>
        </div>
      </DrawerContainer>
    </Modal>
  );
}

const DrawerContainer = styled.div`
  max-width: 516px;
  min-height: 318px;
  width: 100%;
  background-color: white;
  outline: none;
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 8px;
  transform: translate(-50%, -50%);
  & .loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  & .no_result {
    font-size: 16px;
    display: flex;
    flex-direction: row;
    margin: 10px 0px;
    justify-content: center;
    align-items: center;
    color: ${COLORS.COLOR_DARK};
    font-family: ${theme.fonts.primaryFontSemiBold};
  }
  & .cm_row {
    display: flex;
    flex-direction: row;
    padding: 10px 0px;
    align-items: center;
    & .cm_name {
      font-size: 16px;
      font-weight: 600;
      flex: 1;
      color: ${COLORS.COLOR_DARK};
      font-family: ${theme.fonts.primaryFontSemiBold};
    }
    & .add_circle {
      margin: 0px 20px;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(0, 131, 140, 0.13);
      cursor: pointer;
      & .add_icon {
        font-size: 14px;
        color: ${COLORS.BTN_GREEN};
      }
    }
  }
`;

const HeadingContainer = styled.div`
  padding-top: 30px;
  padding-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const CloseModal = styled.img`
  width: 14px;
  height: 14px;
  object-fit: contain;
  cursor: pointer;
  position: absolute;
  right: 30px;
`;

const StyledTextSemi = styled.a`
  font-family: ${theme.fonts.primaryFontSemiBold};
`;

const StyledText = styled.a`
  font-family: ${theme.fonts.primaryFontExtraBold};
`;

const Heading = styled.h3`
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${COLORS.COLOR_DARK};
  font-family: ${theme.fonts.primaryFontExtraBold};
`;
