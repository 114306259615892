import React, { useState, useEffect } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { CustomInputField } from "../../../common/FormInputs";
import CaseService from "../../../../services/CaseService";
import styled from "styled-components";
import { PrimaryCTAButton } from "../../../common/Buttons";

const App = (id) => {
  const [copied, setCopied] = useState(false);
  const [data, setData] = useState("");

  console.log("copyto", id);
  useEffect(() => {
    async function getGuestMeeting() {
      try {
        let caseId = id?.id;
        const meetingurl = await CaseService.getGuestUserMeeting(caseId);
        setData(meetingurl);
        console.log("copyto", meetingurl);
      } catch (error) {}
    }
    getGuestMeeting();
  }, []);

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {data ? (
        <HalfWidth>
          <CustomInputField
            type={"text"}
            value={data}
            disabled={true}
            placeholder="Type Something to Copy"
            variant="outlined"
          />
        </HalfWidth>
      ) : null}
      {data ? (
        <ButtonContainer style={{ margin: 0, marginLeft: 20 }}>
          <CopyToClipboard text={data} onCopy={() => setCopied(true)}>
            <PrimaryCTAButton>Click to Copy</PrimaryCTAButton>
          </CopyToClipboard>
        </ButtonContainer>
      ) : null}
      {copied}
      {/* {copied ? <p>Copied !</p> : null} */}
    </div>
  );
};

export default App;

const HalfWidth = styled.div`
  width: 50%;
  @media (max-width: 600px) {
    width: 100%;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
`;
