import React, { Fragment, useEffect, useRef, useState } from "react";
import COLORS from "../../../assets/Colors";
import CustomTable from "../../common/CustomTable/CustomTable";
import ActionBar from "../../common/ActionBar";
import _ from "lodash";
import queryString from "query-string";
import labels from "../../../helpers/labels.json";
import { useSnackbar } from "notistack";
import { getErrorMessage } from "../../../helpers/functions";
import CaseService from "../../../services/CaseService";
import styled from "styled-components";
import theme from "../../../assets/theme";
import AuthService from "../../../services/AuthService";
import GenericMeeting from "./GenericMeeting";


const RecordingsTable = () => {
  const [state, setState] = useState({});
  const MTRef = useRef();
  const { enqueueSnackbar } = useSnackbar();
  const [caseManagerId, setcaseManagerId] = useState();

  const getRecordings = async query => {
    try {
      const response = await CaseService.getRecordings(query);
      return { ...response };
    } catch (error) {
      throw error;
    }
  };

  // async function meetingJoinCm(caseManagerId) {
  //   try {
  //     const meetingurl = await CaseService.joinMeeting(caseManagerId);
  //     window.open(meetingurl, "_blank");
  //     return;
  //   } catch (error) {}
  // }

  // const actions = [
  //   {
  //     text: "Join Meeting",
  //     props: {
  //       onClick: () => meetingJoinCm(caseManagerId)
  //     }
  //   }
  // ];

  useEffect(() => {
    async function caseManagerDetails() {
      try {
        const caseManager = await AuthService.profile();
        if (caseManager?.me) {
          setcaseManagerId(caseManager?.me?.id);
        }
        return;
      } catch (error) {}
    }
    caseManagerDetails();
  }, []);

  const columns = [
    {
      field: "Id",
      title: "Id",
      sorting: false,
      render: rowData => (
        <Bold style={{ paddingLeft: "12px" }}>{rowData?.id}</Bold>
      ),
      headerStyle: {
        paddingLeft: "26px"
      }
    },
    {
      field: "name",
      title: "Recording Name",
      sorting: false,
      render: rowData => (
        <HyperLink
          onClick={() => {
            window.open(rowData?.RecordingUrl);
          }}
        >
          {rowData?.name}
        </HyperLink>
      )
    },
    {
      field: "time",
      title: "Created Time",
      sorting: false,
      render: rowData => <Bold>{rowData?.time}</Bold>
    },
    {
      field: "duration",
      title: "Duration",
      sorting: false,
      render: rowData => (
        <Bold style={{ paddingLeft: "15px" }}>
          {rowData?.duration}
        </Bold>
      )
    }
  ];

  const data = query =>
    new Promise(resolve => {
      let params = {
        page: query.page + 1,
        perPage: 10
      };
      if (query.search) {
        params.search = query.search;
      }
      if (query.orderBy?.field) {
        params.sort = query.orderBy.field;
        params.sortDirection = query.orderDirection;
      }
      let stringParams = "";
      if (!_.isEmpty(params)) {
        stringParams = `?${queryString.stringify(params)}`;
      }
      getRecordings(stringParams)
        .then(async result => {
          if (result) {
            resolve({
              data: result.data,
              page: result.page - 1,
              total: result.total
            });
            setState(result);
          }
        })
        .catch(error => {
          enqueueSnackbar(getErrorMessage(error), {
            variant: "error"
          });
          setState({ data: [], lastPage: 1, page: 1, perPage: 10, total: 0 });
          resolve({
            data: [],
            page: 0,
            total: 0
          });
        });
    });

  return (
    <>
      <Fragment>
        <ActionBar breadcrumbs={[_.startCase(labels.generic_meeting_link)]} />
        <div style={{ alignItems: "center" }}>
          <PaddingMeeting>
            <GenericMeeting caseManagerId={caseManagerId} />
          </PaddingMeeting>
        </div>
        <ActionBar breadcrumbs={[_.startCase(labels.recordings)]} />
        <Padding>
          <CustomTable
            hidePagination={state.lastPage === 1}
            pageSize={state?.data?.length ? state?.data?.length : 10}
            pluralTitle={labels.recordings}
            hideFilterBar
            singularTitle=""
            {...{
              columns,
              data,
              MTRef
            }}
            noToolbar
            state={state}
          />
        </Padding>
      </Fragment>
    </>
  );
};

export default RecordingsTable;

const Padding = styled.div`
  padding-top: 30px;
  padding-bottom: 50px;
  padding-left: 35px;
  padding-right: 53px;
`;

const PaddingMeeting = styled.div`
  padding-top: 30px;
  padding-bottom: 10px;
  padding-left: 35px;
  padding-right: 53px;
`;

const HyperLink = styled.span`
  font-family: ${theme.fonts.primaryFontSemiBold};
  cursor: pointer;
  color: ${COLORS.BTN_GREEN};
  text-decoration: underline;
  font-size: 14px;
`;

const Bold = styled.span`
  font-family: ${theme.fonts.primaryFontSemiBold};
`;
