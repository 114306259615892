import Axios from "axios";
import labels from "./labels.json";
import _ from "lodash";
// import { states } from "./constants";
export const getErrorMessage = (error) =>
  error.message
    ? error.message
    : error.error && typeof error.error === "string"
    ? error.error
    : typeof error === "string"
    ? error
    : "Something went wrong!";

export const uploadToAws = async (url, file) => {
  return await new Promise(async (resolve, reject) => {
    try {
      const __ = await Axios.put(url, file);
      if (__) {
        resolve(url);
      } else {
        reject();
      }
    } catch (error) {
      reject(error);
    }
  });
};

export function getPartyLabelByStatus(status) {
  switch (status) {
    case "draft":
      return "Draft";
    case "underReview":
      return "Under Verification";
    case "active":
      return "Active";
    default:
      return _.startCase(status);
  }
}

export function getFileName(uri) {
  if (uri?.length) {
    const names = uri?.split("/");
    if (names.length) {
      return names[names.length - 1];
    }
  }
}

export function getLabelByPartyStatus(status) {
  switch (status) {
    case "underReview":
      return labels.profile_filters.under_verification;
    case "draft":
      return labels.profile_filters.drafts;
    default:
      return labels.profile_filters.active;
  }
}

export function getCaseLabelByStatus({
  status,
  uploadedDocuments,
  subscriptionKind,
}) {
  switch (status) {
    case "draft":
      return "Draft";
    case "pendingRegistrationFee":
      return "Awaiting Payment";
    case "negotiationReached":
      return subscriptionKind === "adhoc" ? "Settled" : "Awaiting Payment";
    case "negotiationOngoing":
      return "Under Resolution";
    case "claimantPaidNegotiationSuccessFee":
      return uploadedDocuments.length > 0 ? "Settled" : "Awaiting Agreement";
    case "respondentPaidNegotiationSuccessFee":
      return uploadedDocuments.length > 0 ? "Settled" : "Awaiting Payment";
    case "awaitingRespondentAcceptance":
      return "Waiting For Respondent";
    case "bothPaidNegotiationSuccessFee":
      return uploadedDocuments.length > 0 ? "Settled" : "Awaiting Agreement";
    default:
      return _.startCase(status);
  }
}

export function formatNumberToMetrics(value = "", unit = "") {
  if (!value || unit !== "INR") return value;

  var afterPoint = "";
  if (value.indexOf(".") > 0)
    afterPoint = value?.substring(value?.indexOf("."), value?.length);
  value = Math.floor(value);
  value = value?.toString();
  var lastThree = value?.substring(value.length - 3);
  var otherNumbers = value?.substring(0, value.length - 3);
  if (otherNumbers !== "") lastThree = "," + lastThree;
  var res =
    otherNumbers?.replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
    lastThree +
    afterPoint;
  return res;
}

export const renderFeeKind = (value, { resolutionKind }) => {
  if (resolutionKind === "mediation") {
    if (_.snakeCase(value) === "case_registration") {
      return value?.replace("Case", "Mediation");
    }
    return value?.replace("Negotiation", "Mediation");
  } else {
    if (_.snakeCase(value) === "case_registration") {
      return value?.replace("Case", "Negotiation");
    }
    return value?.replace("Negotiation", "Negotiation");
  }
};

export const downloadWithDifferenFileName = (url, name) => {
  if (!name) name = url.split("\\").pop().split("/").pop();
  fetch(url, {
    headers: new Headers({
      Origin: window.location.origin,
    }),
    mode: "cors",
  })
    .then((response) => response.blob())
    .then((blob) => {
      let blobUrl = window.URL.createObjectURL(blob);
      console.log("url", blob);
      forceDownload(blobUrl, name);
    })
    .catch((e) => console.error(e));
};

function forceDownload(blob, filename) {
  var a = document.createElement("a");
  a.download = filename;
  a.href = blob;
  a.target = "_blank";
  document.body.appendChild(a);
  a.click();
  a.remove();
}

/**
 * @description Function to parse time stamp
 * @param {*} data
 */
export const parseTimeStamp = (data) => {
  return data + "Z";
};

export const numberFormat = value =>
  new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR"
  }).format(value);
