/* eslint-disable no-useless-escape */
import * as Yup from "yup";
import { states } from "./constants";
import moment from "moment";

const URLREGEX = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;

function validatePinCodeByState(state, pin) {
  if (state && pin) {
    const found = states.find((st) => st.value === state);
    const pinStart = pin?.toString().substring(0, 2);
    if (found && found.pinStart.includes(parseInt(pinStart, 10))) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}

export const SampleSchema = Yup.object({
  oldPassword: Yup.string()
    .min(6, "Please enter 6 characters")
    .required("Old Password is required"),
  newPassword: Yup.string()
    .min(6, "Please enter 6 characters")
    .required("New Password is required"),
  confirmPassword: Yup.string()
    .required("Confirm Password is required")
    .min(6, "Password must be minimum 6 characters")
    .test("passwords-match", "Passwords do not match", function (value) {
      return this.parent.newPassword === value;
    }),
});

export const OfflinePaymentSchema = Yup.object({
  paymentType: Yup.string().trim().required("Payment type is required"),
  details: Yup.string().optional(),
});

export const EditProfileSchema = Yup.object({
  name: Yup.string().trim().required("Name is required"),
  houseNumber: Yup.string().trim().required("Address is required"),
  locality: Yup.string().trim().required("Locality is required"),
  city: Yup.string().trim().required("City is required"),
  state: Yup.string().trim().required("State is required"),
  pin: Yup.number()
    .required("Pincode is required")
    .test("pincode", "PIN is required", async function (value) {
      if (value?.toString()?.length === 6) {
        return validatePinCodeByState(this.parent.state, value);
      } else {
        return false;
      }
    }),
  landmark: Yup.string().trim().required("Landmark is required"),
});

export const LoginEmailValidationSchema = Yup.object({
  email: Yup.string().email("Please enter valid email").required(),
  password: Yup.string()
    .min(6, "Please enter 6 characters")
    .required("Password is required"),
});

const MOBILE_REGEX = /^\s*(?:\+?(\d{2}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;

export const LoginMobileValidationSchema = Yup.object({
  mobile: Yup.string("Please enter valid phone number")
    .matches(MOBILE_REGEX, "Please enter valid phone number")
    .required("Phone number is required"),
});

export const FAQValidationSchema = Yup.object({
  sections: Yup.array(
    Yup.object({
      question: Yup.string().trim().required("Question is mandatory"),
      answer: Yup.string().trim().required("Answer is mandatory"),
    })
  ),
});
export const TutorialValidationSchema = Yup.object({
  tutorialUrl: Yup.string().trim().required("Tutorial Video is mandatory"),
});

export const UploadAgreementValidationSchema = Yup.object({
  agreement_url: Yup.string()
    .trim()
    .required("Agreement Document is mandatory"),
});

export const AddZoomLinkValidation = Yup.object({
  zoomLink: Yup.string()
    .trim()
    .required("Video call link is mandatory")
    .matches(URLREGEX, "please enter the valid video call link"),
  startDate: Yup.string().nullable().trim().required("Date is mandatory"),
  startTime: Yup.string()
    .nullable()
    .trim()
    .required("Time is mandatory")
    .test(
      "MIN_TIME",
      "Start time must be greater then current time",
      function (value) {
        const date = moment(new Date(this.parent.startDate)).format(
          "YYYY-MM-DD"
        );
        const time = moment(new Date(value)).format("HH:mm:ss");
        const selectedTime = moment(`${date} ${time}`);
        return moment(new Date(selectedTime)).isAfter(moment(new Date()));
      }
    ),
});

export const UploadSummaryValidationSchema = Yup.object({
  summary: Yup.string().trim().required("Agreement Summary is mandatory"),
});

export const refundScheme = Yup.object({
  payments: Yup.array(
    Yup.object({
      amount: Yup.string()
        .trim()
        .test(
          "amount test",
          "Refund amount should be lesser than original amount",
          function (value) {
            if (this.parent?.checked && this.parent.type === "partial") {
              value = value?.toString()?.split(",")?.join("");
              if (
                Number(value) <= 0 ||
                Number(value) > Number(this.parent?.cartItem?.fee?.discPrice)
              ) {
                return false;
              }
              return true;
            }
            return true;
          }
        )
        .typeError("Refund amount cannot be empty/zero"),
    })
  ),
  comment: Yup.string().trim().required(),
});

export const AddHearingDateValidation = Yup.object({
  startDate: Yup.string().nullable(),
  startTime: Yup.string()
    .nullable()
    .trim()
    .test("startTime", "", function(value) {
      if (this?.parent?.startDate) {
        const matched = value?.startDate;
        if (matched) {
          return true;
        } else {
          if (!value)
            return this.createError({
              message: "Time is required",
              path: "startTime"
            });
        }
      }
    })
});