import API from "../api/manager";
import config from "../api/config";

const index = (query) => {
  return API({
    method: "GET",
    url: config.urls.party.index + query,
  });
};

const getParty = (id) => {
  return API({
    method: "GET",
    url: config.urls.party.get + `/${id}`,
  });
};

const approveParty = (id) => {
  return API({
    method: "POST",
    url: config.urls.party.approve + `/${id}`,
  });
};

const stats = (id) => {
  return API({
    method: "GET",
    url: config.urls.party.stats + `/${id}`,
  });
};

const partyStats = () => {
  return API({
    method: "GET",
    url: config.urls.party.partyStats,
  });
};

const getProfileParties = (query, id) => {
  return API({
    method: "GET",
    url: config.urls.party.profileParties + `/${id}` + query,
  });
};

export default {
  index,
  getParty,
  approveParty,
  stats,
  getProfileParties,
  partyStats,
};
