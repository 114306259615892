import React from "react";
import { Router } from "@reach/router";
import ManagerLogin from "./components/pages/ManagerLogin";
import DashboardScreens from "./components/pages/DashboardScreens";
import MyTasks from "./components/pages/MyTasks";
import PendingActions from "./components/internal/PendingActions";
import Uploads from "./components/internal/Uploads";
import VerifyDetails from "./components/internal/VerifyDetails";
import LoginPath from "./components/pages/ManagerLogin/LoginPath";
import PartyDetails from "./components/internal/PartyDetails";
import Party from "./components/pages/TemporatyRoute";
import Profiles from "./components/pages/TemporatyRoute";
import VerifyParties from "./components/pages/TemporatyRoute";
// import NegotiationListing from "./components/pages/TemporatyRoute";
import MediationListing from "./components/pages/TemporatyRoute";
import ArbitrationListing from "./components/pages/TemporatyRoute";
import MyCases from "./components/internal/MyCases";
import MyProfiles from "./components/internal/MyProfiles";
import CaseDetails from "./components/pages/CaseDetails";
// import Configurations from "./components/pages/Configurations";
// import MyConfigurations from "./components/internal/MyConfigurations";
// import UpdateTerminologies from "./components/internal/UpdateTerminologies";
// import UpdateFAQs from "./components/pages/UpdateFAQs";
import ProfileDetails from "./components/pages/ProfileDetails";
import BulkUploads from "./components/pages/BulkUploads";
import labels from "./helpers/labels.json";
import Parties from "./components/internal/MyParties";
import MailRedirection from "./components/pages/MailRedirection";
import ProformaInvoiceListing from "./components/internal/ProformaInvoiceListing";
import Recordings from "./components/pages/Recordings/index";
import RecordingsPage from "./components/internal/RecordingsPage/index";
import GuestGenericMeeting from "./components/pages/JoinGenericMeeting";


const AppRouter = () => {
  return (
    <Router>
      <LoginPath path="/">
        <ManagerLogin path="/" />
      </LoginPath>
      <DashboardScreens path="/dashboard">
        <MyTasks path="/">
          <PendingActions path="/" />
          <ProformaInvoiceListing path="/proforma-invoice" />
          <Uploads path="/uploads" />
          <VerifyParties
            selectedItem={labels.my_tasks}
            selectedSubItem={labels.verify}
            path="/verify-parties"
          >
            <VerifyDetails path="/" />
            <PartyDetails path="/:partyId" />
          </VerifyParties>
        </MyTasks>
        {/* <NegotiationListing
          path="/negotiation"
          selectedSubItem={labels.negotiation}
          selectedItem={labels.cases}
        >
          <MyCases path="/" type="negotiation" />
          <CaseDetails path="/:id" />
        </NegotiationListing> */}
        <MediationListing
          path="/mediation"
          selectedSubItem={labels.mediation}
          selectedItem={labels.cases}
        >
          <MyCases path="/" type="mediation" />
          <CaseDetails path="/:id" />
        </MediationListing>
        <ArbitrationListing
          path="/arbitration"
          selectedSubItem={labels.arbitration}
          selectedItem={labels.cases}
        >
          <MyCases path="/" type="arbitration" />
          <CaseDetails path="/:id" />
        </ArbitrationListing>
        <Party selectedItem={labels.parties} path="/parties">
          <Parties path="/" />
          <PartyDetails path="/:partyId" isFromListing={true} />
        </Party>
        <Profiles selectedItem={labels.profiles} path="/profiles">
          <MyProfiles path="/" />
          <ProfileDetails path="/:userId" />
        </Profiles>
        <BulkUploads path="/bulk-uploads" />
        <Recordings selectedItem={labels.meeting_details} path="/recordings">
          <RecordingsPage path="/" />
        </Recordings>
        <MailRedirection path="/cases/:id" redirectTo="case" />
      </DashboardScreens>
      <GuestGenericMeeting path="/joinMeeting/:code" />
    </Router>
  );
};

export default AppRouter;
