import React, { useState, useRef } from "react";
import { Heading } from "../../../styles/component/style";
import styled from "styled-components";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";
import { HyperLink } from "../../../styles/component/style";
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  makeStyles
} from "@material-ui/core";
import moment from "moment";
import useLoader from "../../../hooks/useLoader";
import AWSService from "../../../services/AWSService";
import { useSnackbar } from "notistack";
import {
  numberFormat,
  getErrorMessage,
  parseTimeStamp
} from "../../../helpers/functions";
import CaseService from "../../../services/CaseService";
import CaseDescriptionModal from "../CaseDescriptionModal";
import AgentListModal from "../AgentListModal/AgentListDrawer";
import DocumentUploadModal from "../DocumentUploadModal";
import RefundModal from "../RefundModal";
import Accordion from "@material-ui/core/ExpansionPanel";
import AccordionSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { AccordionHeading } from "../../internal/DrawerTable";
import ViewNotesModal from "../../internal/ViewNotesModal";
import ClaimItemsListModal from "../../common/CaseItemsListModal/index";
import HearingDate from "../../internal/HearingDate/index";

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: "none",
    border: `solid 0.5px #e1e3ee`
  },
  content: {
    margin: `0 !important`,
    "& .MuiExpansionPanelSummary-content.Mui-expanded": {
      margin: `0 !important`
    }
  },
  details: {
    display: "flex",
    flexDirection: "column",
    padding: `0 !important`
  }
}));

const Index = ({
  caseDetails,
  setState,
  id,
  MTRef,
  isActionsDisabled = false,
  partyList = [],
  refreshDetails,
  setRefreshDetails,
  isViewAction = false,
  pendingRegistrationFee,
  setOpenPaymentBox,
  disableRefund
}) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(true);
  const fileRef = useRef();
  const { setLoader } = useLoader();
  const { enqueueSnackbar } = useSnackbar();
  const [file] = useState([]);
  const [modal, setModal] = useState();
  const [docUploadModal, setDocUploadModal] = useState();
  const [url, setUrl] = useState("");
  const [openRefundModal, setOpenRefundModal] = useState(false);
  const [opendrawer, setOpenDrawer] = useState(false);
  const [viewNotes, setViewNotes] = useState(false);
  const [opendrawerClaim, setOpenDrawerClaim] = useState(false);
  const [openHearing, setOpenHearing] = useState(false);

  async function s3Upload(file) {
    if (file) {
      try {
        setLoader({ state: true, message: `Uploading file ${file.name} ...` });
        const payload = {
          key: file.name
        };
        const s3Response = await AWSService.getS3URL(payload);
        if (s3Response.url) {
          const res = await AWSService.uploadToS3(s3Response.url, file);
          setUrl(res);
        }
      } catch (error) {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, { variant: "error" });
      } finally {
        setLoader({ state: false });
      }
    }
  }

  /** Function to get the negotiation round Details */
  async function addDocumentForCase(checked, checkedme, type) {
    let visibilityType;
    if (!checked && !checkedme) visibilityType = "me";
    else if (checked && !checkedme) visibilityType = "others";
    else if (!checked && checkedme) visibilityType = "all";
    try {
      const postData = {
        url: {
          uploadType: type || "",
          url: url
        },
        visibilityType: visibilityType
      };
      const upload_response = await CaseService.caseAddDocument(postData, id);
      enqueueSnackbar("Successfully Uploaded", { variant: "success" });
      if (MTRef?.current?.onSearchChange) {
        MTRef.current.onSearchChange("");
      }
      setDocUploadModal(false);
      setUrl("");
      console.log(upload_response);
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error"
      });
    } finally {
      setLoader({ state: false });
    }
  }

  return (
    <>
      <ExpansionPanel className={classes.root} expanded={expanded}>
        <ExpansionPanelSummary
          aria-controls="panel1a-content"
          id="panel1a-header"
          classes={{ content: classes.content }}
        >
          <Header>
            <Heading style={{ marginBottom: "unset", marginLeft: "21px" }}>
              {caseDetails.title}
            </Heading>
            <Actions>
              {caseDetails?.resolutionKind === "mediation" ? (
                <HyperLink onClick={() => setViewNotes(true)}>
                  Case Manager
                  <br />
                  Scratch Pad
                </HyperLink>
              ) : caseDetails?.resolutionKind === "arbitration" ? (
                <HyperLink onClick={() => setViewNotes(true)}>
                  Case Manager
                  <br />
                  Scratch Pad
                </HyperLink>
              ) : (
                ""
              )}
              {/* <HyperLink
                disabled={disableRefund}
                onClick={() => !disableRefund && setOpenRefundModal(true)}
              >
                Refund
              </HyperLink> */}
              {partyList.length ? (
                <HyperLink
                  style={{ display: "table" }}
                  onClick={() => setOpenDrawer(a => ({ ...a, state: true }))}
                >
                  Agent Details
                </HyperLink>
              ) : null}
              <HyperLink
                disabled={isActionsDisabled}
                onClick={() => !isActionsDisabled && setDocUploadModal(true)}
              >
                Add Document
              </HyperLink>
              <HyperLink onClick={() => setState(true)}>
                Case Documents
              </HyperLink>
              {/* {pendingRegistrationFee && (
                <HyperLink onClick={(e) => setOpenPaymentBox(true)}>
                  Mark as paid
                </HyperLink>
              )} */}
              <DropDown>
                <Icon
                  onClick={() => setExpanded(e => !e)}
                  src={require("../../../assets/images/dropdownIcon.svg")}
                  alt="dropdown"
                  expanded={expanded}
                />
              </DropDown>
            </Actions>
          </Header>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.details}>
          <TableWrapper>
            <tbody>
              <Tr>
                <Th>Case ID</Th>
                <Th>Claimant</Th>
                <Th>Respondent</Th>
                <Th>Monetary Claim Value</Th>
                <Th>Filed on</Th>
                <Th>Last Updated</Th>
                {caseDetails?.status === "draft" ? (
                  ""
                ) : (
                  <Th>Next Hearing Date</Th>
                )}
              </Tr>
              <Tr>
                <Td>{caseDetails.id}</Td>
                <Td>{caseDetails?.claimantParty?.name}</Td>
                <Td>
                  {caseDetails?.respondentParty?.name ||
                  caseDetails.respondentName ? (
                    <>
                      {caseDetails?.respondentParty?.name
                        ? caseDetails?.respondentParty?.name
                        : caseDetails.respondentName}
                    </>
                  ) : (
                    <>
                      {caseDetails?.respondentParties?.length
                        ? caseDetails?.respondentParties[0]?.name
                        : ""}
                    </>
                  )}
                  {partyList?.filter(el => el.partyRole === "respondent")
                    ?.length -
                    1 >
                  0 ? (
                    <HyperLink
                      style={{ marginLeft: 8, display: "inline" }}
                      onClick={() =>
                        setOpenDrawer(a => ({ ...a, state: true }))
                      }
                    >
                      +{" "}
                      {partyList?.filter(el => el.partyRole === "respondent")
                        ?.length - 1}
                    </HyperLink>
                  ) : null}
                </Td>
                <Td>
                  {caseDetails.totalClaimValue
                    ? numberFormat(
                        parseFloat(caseDetails.totalClaimValue).toFixed(2)
                      )
                    : // formatNumberToMetrics(
                      //   parseFloat(caseDetails.totalClaimValue).toFixed(2),
                      //   "INR"
                      // )
                      "Non Monetary"}
                  {caseDetails?.resolutionKind === "mediation" &&
                  caseDetails?.caseItems?.length ? (
                    <HyperLink
                      style={{ marginLeft: 8, display: "inline" }}
                      onClick={() =>
                        setOpenDrawerClaim(a => ({ ...a, state: true }))
                      }
                    >
                      +{" "}
                    </HyperLink>
                  ) : (
                    ""
                  )}
                </Td>
                <Td>
                  {caseDetails.created_at &&
                    moment(parseTimeStamp(caseDetails.created_at)).format(
                      "DD/MM/YYYY"
                    )}
                </Td>
                <Td>
                  {caseDetails.updated_at &&
                    moment(parseTimeStamp(caseDetails.updated_at)).format(
                      "DD/MM/YYYY"
                    )}
                </Td>
                {caseDetails?.status === "draft" ? (
                  ""
                ) : (
                  <Td>
                    {caseDetails?.nextHearingDate?.date?.length
                      ? caseDetails?.nextHearingDate?.time
                      : "N/A"}
                    <HyperLink
                      style={{ marginLeft: 8, display: "inline" }}
                      // onClick={() => setOpenHearing(true)}
                      onClick={() =>
                        setOpenHearing(a => ({ ...a, state: true }))
                      }
                    >
                      Update{" "}
                    </HyperLink>
                  </Td>
                )}
              </Tr>
            </tbody>
          </TableWrapper>
          <TableWrapper style={{ marginTop: 25, marginBottom: 15 }}>
            <tbody>
              <Tr>
                <Td>
                  <Accordion className={classes.root}>
                    <AccordionSummary
                      style={{ marginRight: "20px" }}
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <AccordionHeading style={{ paddingLeft: "20px" }}>
                        Case Description
                      </AccordionHeading>
                    </AccordionSummary>
                    <CommentContent
                      style={{
                        paddingLeft: "20px",
                        paddingRight: "20px",
                        paddingBottom: "30px",
                        paddingTop: "auto",
                        maxHeight: 400,
                        overflow: "auto"
                      }}
                    >
                      {caseDetails?.description}
                    </CommentContent>
                  </Accordion>
                </Td>
              </Tr>
            </tbody>
          </TableWrapper>

          <Row>
            <Column>
              {caseDetails?.resolutionKind === "negotiation" ? (
                <div>
                  <span className="label">Total Rounds:</span>{" "}
                  {caseDetails?.negotiationRoundLimit}
                </div>
              ) : null}
            </Column>
          </Row>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <AgentListModal
        {...{
          opendrawer,
          setOpenDrawer,
          MTRef,
          partyList,
          displayAuthUrl: false,
          caseDetails
        }}
      />
      <ClaimItemsListModal
        {...{
          opendrawerClaim,
          setOpenDrawerClaim,
          MTRef,
          displayAuthUrl: false
        }}
        caseDetails={caseDetails}
      />
      <HearingDate
        {...{ openHearing, setOpenHearing, refreshDetails, setRefreshDetails }}
        caseDetails={caseDetails}
      />
      <ViewNotesModal
        disabled={isActionsDisabled}
        modal={viewNotes}
        setModal={setViewNotes}
        type={"Case Manager"}
        id={id}
      />
      <CaseDescriptionModal
        {...{ modal, setModal }}
        description={caseDetails?.description}
      />
      <RefundModal
        id={caseDetails?.id}
        {...{ openRefundModal, setOpenRefundModal }}
        heading="Refund Payment"
        btnText="Refund Payment"
      />
      <DocumentUploadModal
        modal={docUploadModal}
        setModal={setDocUploadModal}
        handleUpload={s3Upload}
        file={file}
        fileRef={fileRef}
        url={url}
        setUrl={setUrl}
        handleSuccess={(checked, checkedme, type) =>
          addDocumentForCase(checked, checkedme, type)
        }
        hasDocumentType={caseDetails?.resolutionKind === "arbitration"}
      />
    </>
  );
};

export default Index;

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0px;
  width: 100%;
  cursor: default;
`;
const Actions = styled.div`
  display: flex;
  align-items: center;
  & div {
    margin-left: 35px;
  }
`;
const DropDown = styled.div`
  width: 24px;
  height: 24px;
  margin: 0 17px 0 25px;
  border-radius: 50%;
  background-color: ${COLORS.TABLE_GREY_CELL};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
const TableWrapper = styled.table`
  margin: 6px 0 7px 0;
  padding: 0 21px 0 21px;
  width: 100%;
`;
const Tr = styled.tr``;
const Th = styled.th`
  font-family: ${theme.fonts.primaryFontRegular};
  font-size: 10px;
  text-transform: capitalize;
  font-weight: 600;
  line-height: 1.7;
  color: ${COLORS.INPUT_LABEL};
  text-align: left;
  // width: 16.66%;
  width: 0.5%;
`;
const Td = styled.td`
  font-family: ${theme.fonts.primaryFontBold};
  font-size: 14px;
  color: #293461;
`;

const Column = styled.div`
  padding: 20px 22px;
  max-width: 50%;
  font-family: ${theme.fonts.primaryFontRegular};
  font-size: 12px;
  color: ${COLORS.COLOR_DARK};
  & div {
    margin-bottom: 10px;
    font-family: ${theme.fonts.primaryFontBold};
    font-size: 14px;
    color: #293461;
  }
  & .label {
    font-size: 10px;
    font-weight: 600;
    color: ${COLORS.INPUT_LABEL};
  }
`;

const Row = styled.div`
  display: flex;
`;

const Icon = styled.img`
  width: 11px;
  height: 6px;
  object-fit: "contain";
  transform: rotate(${({ expanded }) => (expanded ? 180 : 0)}deg);
  transition: 5sec;
`;

const CommentContent = styled.div`
  font-size: 14px;
  word-break: break-word;
  text-align: justify;
  color: ${COLORS.COLOR_DARK};
  font-family: ${theme.fonts.primaryFontSemiBold};
  // margin-top: 10px;
  & strong,
  & bold {
    font-family: ${theme.fonts.primaryFontExtraBold};
  }
  & .ql-align-center {
    text-align: center;
  }
  & .ql-align-right {
    text-align: right;
  }
  // & blockquote {
  //   border-left: 4px solid #ccc;
  //   margin-bottom: 5px;
  //   margin-top: 5px;
  //   padding-bottom: 5px;
  //   padding-top: 5px;
  //   padding-left: 16px;
  //   background-color: #f9f9f9;
  // }
`;
