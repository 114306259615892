import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";

import StyledAppBar from "./StyledAppBar";
import Sidebar from "./Sidebar";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  content: {
    flexGrow: 1,
    marginTop: 74,
  },
}));

export function Header({ children, showSidebar = false, selectedItem, selectedSubItem }) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <CssBaseline />
      <StyledAppBar />
      {showSidebar && <Sidebar {...{ selectedItem, selectedSubItem }} />}
      <main className={classes.content}>{children}</main>
    </div>
  );
}
