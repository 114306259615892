import React, { Fragment, useState, useEffect, useRef } from "react";
import ActionBar from "../../common/ActionBar";
import { PageWrapper, StyledLabel } from "./styles";
import LifeCycle from "../../common/LifeCycle";
import CaseDetailsCard from "../../common/CaseDetails";
import CaseManager from "./common/CaseManager";
import BigBlueButton from "./common/BigBlueButton";
import _ from "lodash";
import { navigate } from "@reach/router";
import CaseService from "../../../services/CaseService";
import useLoader from "../../../hooks/useLoader";
import {
  AWAITING_RESPONDANT,
  RESPONDANT_ONBOARDED,
  CLAIMENT_STR,
  CLAIMANT_LIFE_CYCLE,
  NEGOTIATION_REACHED,
  NEGOTIATION_QUIT_BY_CLAIMENT,
  NEGOTIATION_QUIT_BY_RESPONDENT,
  NEGOTIATION_QUIT_BY_CASEMANAGER,
  CLAIMENT_PAID_NEGOTIATION,
  RESPONDENT_PAID_NEGOTIATION,
  BOTH_PAID_NEGOTIATION
} from "../../../helpers/constants";
import { getErrorMessage } from "../../../helpers/functions";
import { useSnackbar } from "notistack";
import NegotiationState from "./NegotiationState";
import DrawerTable from "../../internal/DrawerTable";
import PaymentModal from "./common/PaymentModal";
import AssignCaseMediator from "./common/AssignCaseMediatorModal";
import MediationState from "./MediationState";
import CaseCTA from "./common/CaseCTA";
import theme from "../../../assets/theme";
import AlertDialog from "../../common/Alert";
import queryString from "query-string";
import useNotification from "../../../hooks/useNotification";
import CopytoClipboard from "./common/CopytoClipboard.js";

export default function CaseDetails({ id }) {
  const initialState = ["Cases"];
  const [state, setState] = useState();
  const [breadcrumbs, setBreadcrumbs] = useState(initialState); // This state is to display the bread crum
  const { setLoader } = useLoader(); // This Hook is to display the loader
  const [caseDetails, setCaseDetails] = useState({}); // This state holds the case detaisl
  const [caesLifeCycle, setCaseLifeCyle] = useState([]); // This state holds the case lifecycle
  const [isRespondent, setIsRespondent] = useState(false); // This state tells current logged in user is respondent or not
  const [ownerId, setOwnerId] = useState(); // this state holds the ownerId
  const [respondentId, setRespondentId] = useState(); // THis state holds the respondent Id
  const { enqueueSnackbar } = useSnackbar(); // This Hook is used to trigger the snackbar message
  const [negotiationRounds, setNegotiationRounds] = useState({}); // THis state holds the NegotiationRounds
  const [currentState, setCurrentState] = useState({}); // This state Holds the current state
  const MTRef = useRef();
  const [openPaymentBox, setOpenPaymentBox] = useState(false);
  const [pendingRegistrationFee, setPendingRegistrationFee] = useState(false);
  const [openAssignCaseManager, setOpenAssignCaseManager] = useState(false);
  const [refreshDetails, setRefreshDetails] = useState(true);
  const [comments, setComments] = useState([]); // This is list of comments
  const [dialogData, setDialogData] = useState({}); // This state is used to set the dialog content
  const [open, setOpen] = useState(false); //This state is for open and Close the model
  const [partyList, setPartyList] = useState([]);
  const { triggerNotify, setTriggerNotify } = useNotification(); // hooks to refresh the page

  useEffect(() => {
    if (triggerNotify) {
      setRefreshDetails(true);
      setTriggerNotify(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerNotify]);

  /**THis useEffect is Used to get the case information */
  useEffect(() => {
    /** Function to get the CaseDetails */
    async function getCaseById(id) {
      setLoader({ state: true, message: "Fetching case details..." });
      try {
        const res = await CaseService.getCase(id);
        if (res) {
          let case_response = { ...res };
          if (case_response.status !== "draft") {
            if (case_response.status === "pendingRegistrationFee") {
              setPendingRegistrationFee(true);
            }
            if (!case_response.respondentPartyId) {
              case_response.status = AWAITING_RESPONDANT;
            } else if (
              case_response.respondentStatus === "pending" ||
              case_response.respondentStatus === "declined"
            ) {
              case_response.status = RESPONDANT_ONBOARDED;
            } else if (
              case_response?.status === NEGOTIATION_REACHED &&
              case_response?.subscriptionKind === "adhoc"
            ) {
              case_response.status = BOTH_PAID_NEGOTIATION;
            }
          }
          if (case_response?.resolutionKind === "negotiation") {
            getCaseNegotiations(id);
          } else {
            getCaseMediation(id);
          }
          getparty(id);
          setCaseDetails(case_response);
          setBreadcrumbs([
            "Cases",
            _.startCase(case_response?.resolutionKind),
            case_response.title
          ]);
          setCaseLifeCyle(JSON.parse(JSON.stringify(CLAIMANT_LIFE_CYCLE)));
          setIsRespondent(case_response.agentRole !== CLAIMENT_STR);
          setOwnerId(case_response.claimantPartyId);
          setRespondentId(case_response.respondentPartyId);
        }
      } catch (error) {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, {
          variant: "error"
        });
        navigate("/dashboard/negotiation");
      } finally {
        setLoader({ state: false });
      }
    }

    async function getparty(id) {
      let params = {
        page: 1,
        perPage: 1000
      };
      const partyParam = `?${queryString.stringify(
        params
      )}&sort=partyRole&sortDirection=asc`;
      try {
        const party_list_res = await CaseService.getPartyListByCaseId(
          id,
          partyParam
        ); // This is for getting the party details based on the case
        if (party_list_res?.data) {
          setPartyList(party_list_res?.data);
        }
      } catch (error) {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, {
          variant: "error"
        });
      }
    }

    /** Function to get the negotiation round Details */
    async function getCaseMediation(id) {
      setLoader({ state: true, message: "Fetching case details..." });
      try {
        let params = {
          page: 1,
          perPage: 1000
        };
        let stringParams = "";
        if (!_.isEmpty(params)) {
          stringParams = `?${queryString.stringify(params)}`;
        }
        const mediation_res = await CaseService.mediations(id, stringParams);
        if (mediation_res) {
          setComments(mediation_res?.data);
        }
      } catch (error) {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, {
          variant: "error"
        });
      } finally {
        setLoader({ state: false });
      }
    }

    /** Function to get the negotiation round Details */
    async function getCaseNegotiations(id) {
      setLoader({ state: true, message: "Fetching case details..." });
      try {
        const case_response = await CaseService.negotiations(id);
        setNegotiationRounds(case_response);
      } catch (error) {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, {
          variant: "error"
        });
      } finally {
        setLoader({ state: false });
      }
    }
    if (id && refreshDetails) {
      getCaseById(id);
      setRefreshDetails(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshDetails]);

  /** THis is useEffect is used to set the curretState based on the status **/
  useEffect(() => {
    if (caesLifeCycle.length && !_.isEmpty(caseDetails)) {
      if (caesLifeCycle.some(el => el.key === caseDetails.status)) {
        setCurrentState(
          caesLifeCycle.find(el => el.key === caseDetails.status)
        );
      } else if (!isRespondent) {
        if (caseDetails.respondentId) {
          setCurrentState(caesLifeCycle.find(el => el.index === 2));
        } else {
          setCurrentState(caesLifeCycle.find(el => el.index === 1));
        }
      }
    }
  }, [caesLifeCycle, caseDetails, isRespondent]);

  const onBreadcrumbClick = selected => {
    switch (_.snakeCase(selected)) {
      case _.snakeCase(caseDetails?.resolutionKind):
        navigate(`/dashboard/${caseDetails?.resolutionKind}`);
        break;
      case "cases":
        navigate(`/dashboard/${caseDetails?.resolutionKind}`);
        break;
      default:
        break;
    }
  };

  /**
   * @description Function to trigger the mediation success
   */
  async function callMediationSucess() {
    setLoader({ state: true, message: "Updating case details..." });
    try {
      const res = await CaseService.mediationSuccess(id);
      if (res?.message) {
        enqueueSnackbar(res?.message, {
          variant: "success"
        });
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error"
      });
    } finally {
      setOpen(false);
      setLoader({ state: false });
      setRefreshDetails(true);
    }
  }

  /**
   * @description Function to trigger the Quit mediation
   */
  async function callQuitCase() {
    setLoader({ state: true, message: "Updating case details..." });
    try {
      const res = await CaseService.quitMediation(id);
      if (res?.message) {
        enqueueSnackbar(res?.message, {
          variant: "success"
        });
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error"
      });
    } finally {
      setOpen(false);
      setLoader({ state: false });
      setRefreshDetails(true);
    }
    setOpen(false);
  }

  async function callQuitNegotiationCase() {
    setLoader({ state: true, message: "Updating case details..." });
    try {
      const res = await CaseService.quitNegotiation(id);
      if (res?.message) {
        enqueueSnackbar(res?.message, {
          variant: "success"
        });
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error"
      });
    } finally {
      setOpen(false);
      setLoader({ state: false });
      setRefreshDetails(true);
    }
    setOpen(false);
  }

  async function callReopenCase() {
    setLoader({ state: true, message: "Updating case details..." });
    try {
      const res = await CaseService.updateCaseReopen(id);
      if (res?.message) {
        enqueueSnackbar(res?.message, {
          variant: "success"
        });
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error"
      });
    } finally {
      setOpen(false);
      setLoader({ state: false });
      setRefreshDetails(true);
    }
    setOpen(false);
  }

  const onbuttonClick = type => {
    setOpen(true);
    switch (type) {
      case "MEDIATION_SUCCESS":
        setDialogData({
          primaryBtnText: "Cancel",
          secondaryBtnText: "Proceed",
          clickSecondarybtn: () => callMediationSucess(),
          clickPrimaryBtn: () => setOpen(false),
          desc: `Congratulations, your ${caseDetails?.resolutionKind} has been a success. Are you sure you want to proceed?`,
          heading: `${_.capitalize(caseDetails?.resolutionKind)} Success`,
          buttonType: type,
          descriptionTextStyle: {
            textAlign: "left",
            fontFamily: theme.fonts.primaryFontSemiBold
          }
        });
        break;
      case "QUIT":
        setDialogData({
          primaryBtnText: "Don't Quit",
          secondaryBtnText: "Yes, Quit",
          clickSecondarybtn: () => {
            caseDetails?.resolutionKind !== "negotiation"
              ? callQuitCase()
              : callQuitNegotiationCase();
          },
          clickPrimaryBtn: () => setOpen(false),
          desc: `Please note once you quit, you cannot continue your ${caseDetails?.resolutionKind}. Yes to Quit, No to Don't Quit.`,
          heading: "Do you really want to Quit your case?",
          buttonType: type,
          descriptionTextStyle: {
            textAlign: "left",
            fontFamily: theme.fonts.primaryFontSemiBold
          }
        });
        break;
      case "REOPEN_CASE":
        setDialogData({
          primaryBtnText: "Cancel",
          secondaryBtnText: "Proceed",
          clickSecondarybtn: () => callReopenCase(),
          clickPrimaryBtn: () => setOpen(false),
          desc: `Click proceed to Reopen your case, Click cancel to Cancel your action`,
          heading: "Do you really want to Reopen your case?",
          buttonType: type,
          descriptionTextStyle: {
            textAlign: "left",
            fontFamily: theme.fonts.primaryFontSemiBold
          }
        });
        break;
      default:
        break;
    }
  };

  async function callRemoveMediator(mediator) {
    setLoader({ state: true, message: "Removing mediator..." });
    const payload = {
      requestedMediators: caseDetails?.requestedMediators?.filter(
        el => el?.email !== mediator?.email
      )
    };
    try {
      const res = await CaseService.updateCase(id, payload);
      if (res?.message) {
        enqueueSnackbar(res?.message, {
          variant: "success"
        });
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error"
      });
    } finally {
      setOpen(false);
      setLoader({ state: false });
      setRefreshDetails(true);
    }
    setOpen(false);
  }

  const removeMediator = mediator => {
    setOpen(true);
    setDialogData({
      primaryBtnText: "No",
      secondaryBtnText: "Yes",
      clickSecondarybtn: () => callRemoveMediator(mediator),
      clickPrimaryBtn: () => setOpen(false),
      desc: `Are you sure you want to remove this mediator?`,
      heading: "Remove Mediator",
      descriptionTextStyle: {
        textAlign: "left",
        fontFamily: theme.fonts.primaryFontSemiBold
      }
    });
  };

  async function getMeetingjoin(id) {
    try {
      const meetingurl = await CaseService.getMeeting(id);
      window.open(meetingurl);
      return;
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error"
      });
    }
  }

  return (
    <Fragment>
      <ActionBar
        breadcrumbs={breadcrumbs}
        onBreadcrumbClick={onBreadcrumbClick}
      />
      <PageWrapper>
        <div>
          <CaseDetailsCard
            isActionsDisabled={
              currentState?.key === NEGOTIATION_REACHED ||
              currentState?.key === NEGOTIATION_QUIT_BY_CLAIMENT ||
              currentState?.key === NEGOTIATION_QUIT_BY_RESPONDENT ||
              currentState?.key === NEGOTIATION_QUIT_BY_CASEMANAGER ||
              currentState?.key === CLAIMENT_PAID_NEGOTIATION ||
              currentState?.key === RESPONDENT_PAID_NEGOTIATION ||
              currentState?.key === BOTH_PAID_NEGOTIATION
            }
            {...{ setState }}
            disableRefund={
              pendingRegistrationFee || caseDetails?.status === "draft"
            }
            setOpenPaymentBox={setOpenPaymentBox}
            caseDetails={caseDetails}
            pendingRegistrationFee={pendingRegistrationFee}
            refreshDetails={refreshDetails}
            setRefreshDetails={setRefreshDetails}
            {...{
              setState,
              id,
              MTRef,
              partyList
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
          }}
        >
          <div className="flex wrap">
            {caseDetails?.caseManager ? (
              <div className="mr25 mt28">
                <CaseManager
                  caseManager={caseDetails?.caseManager}
                  name={caseDetails?.caseManager?.name}
                  email={caseDetails?.caseManager?.email}
                  mobile={caseDetails?.caseManager?.mobile}
                  avatarUrl={caseDetails?.caseManager?.avatarUrl}
                  showRemove={false}
                />
              </div>
            ) : null}
            {caseDetails?.resolutionKind !== "negotiation" ? (
              <>
                {caseDetails?.requestedMediators?.length ? (
                  <>
                    {caseDetails?.requestedMediators?.map((mediator, idx) => (
                      <div className="mr25 mt28" key={idx}>
                        <CaseManager
                          disabled={false}
                          caseManager={mediator}
                          title={
                            caseDetails?.resolutionKind === "mediation"
                              ? "Mediator"
                              : "Arbitrator"
                          }
                          removeMediator={() => removeMediator(mediator)}
                          addMediator={() => setOpenAssignCaseManager(true)}
                          showRemove={true}
                          adhocPending={!mediator?.id}
                        />
                      </div>
                    ))}
                  </>
                ) : null}
                {(!caseDetails?.requestedMediators?.length ||
                  caseDetails?.resolutionKind === "arbitration") && (
                  <div className="mr25 mt28">
                    <CaseManager
                      disabled={false}
                      title={
                        caseDetails?.resolutionKind === "mediation"
                          ? "Mediator"
                          : "Arbitrator"
                      }
                      addMediator={() => setOpenAssignCaseManager(true)}
                    />
                  </div>
                )}
              </>
            ) : null}
            <div className="mr25 mt28">
              {caseDetails?.resolutionKind !== "negotiation" && (
                <BigBlueButton
                  onClick={() => getMeetingjoin(id)}
                  type={`Join ${_.capitalize(
                    caseDetails?.resolutionKind
                  )} Session`}
                />
              )}
            </div>
          </div>
        </div>
        <div className="mr25 mt28">
          {caseDetails?.resolutionKind !== "negotiation" && (
            <CopytoClipboard id={id} />
          )}
        </div>
        {caseDetails?.status !== "draft" && (
          <>
            <div style={{ marginTop: 28 }}>
              <StyledLabel>Case Lifecycle</StyledLabel>
              <LifeCycle
                items={caesLifeCycle}
                resolutionKind={caseDetails?.resolutionKind}
                selectedIndex={currentState?.index}
                isMediation={caseDetails?.resolutionKind !== "negotiation"}
              />
            </div>
            <div style={{ marginTop: 28 }}>
              {caseDetails?.resolutionKind === "negotiation" ? (
                <NegotiationState
                  {...{
                    currentState,
                    negotiationRounds,
                    isRespondent,
                    ownerId,
                    respondentId,
                    caseDetails
                  }}
                />
              ) : (
                <MediationState
                  {...{
                    currentState,
                    negotiationRounds,
                    isRespondent,
                    ownerId,
                    respondentId,
                    caseDetails,
                    comments,
                    setComments,
                    partyList
                  }}
                />
              )}
            </div>
            <div style={{ marginTop: 28 }}>
              <CaseCTA
                cta={
                  caseDetails?.resolutionKind !== "negotiation"
                    ? currentState.cta
                    : currentState?.cta?.filter(
                        cta => cta.type !== "MEDIATION_SUCCESS"
                      )
                }
                resolutionKind={caseDetails?.resolutionKind}
                disableButton={false}
                onbuttonClick={type => onbuttonClick(type)}
              />
            </div>
          </>
        )}
      </PageWrapper>

      {/* This is for Modal */}
      <div>
        <AlertDialog
          isOpen={open}
          {...{ ...dialogData }}
          descriptionTextStyle={{
            textAlign: "left",
            fontFamily: theme.fonts.primaryFontSemiBold
          }}
        />
      </div>

      <DrawerTable
        {...{ state, setState, id, MTRef }}
        type={caseDetails?.resolutionKind}
        claimantId={caseDetails?.claimantParty?.ownerId}
        respondentId={caseDetails?.respondentParty?.ownerId}
      />
      {pendingRegistrationFee && (
        <PaymentModal
          modal={openPaymentBox}
          setModal={bool => {
            setOpenPaymentBox(false);
            setRefreshDetails(true);
          }}
          id={caseDetails?.id}
        />
      )}

      <AssignCaseMediator
        modal={openAssignCaseManager}
        setModal={setOpenAssignCaseManager}
        type={
          caseDetails?.resolutionKind === "mediation"
            ? "Mediator"
            : "Arbitrator"
        }
        onAssignSuccess={() => {
          setRefreshDetails(true);
          setOpenAssignCaseManager(false);
        }}
        caseDetails={caseDetails}
      />
    </Fragment>
  );
}
