import styled, { css } from "styled-components";
import theme from "../../../assets/theme";
import COLORS from "../../../assets/Colors";

export const PageWrapper = styled.div`
  width: 100%;
  padding: 15px 53px;
`;

export const StyledLabel = styled.div`
  font-family: ${theme.fonts.primaryFontBold};
  font-size: 12px;
  line-height: 1.83;
  text-transform: capitalize;
  color: ${COLORS.COLOR_DARK};
`;

export const CaseManagerCard = styled.div`
  width: 295px;
  height: 77px;
  border-radius: 7px;
  border: solid 0.5px #e1e3ee;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 10px 18px 16px;
  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.6;
      & .add_circle {
        cursor: not-allowed !important;
      }
    `}
  & .add_case_casemanager {
    color: ${COLORS.BTN_GREEN};
    font-family: ${theme?.fonts?.primaryFontSemiBold};
  }
  & .remove {
    position: absolute;
    right: 15px;
    top: 15px;
    height: 16px;
    width: 16px;
    border: 1.5px solid ${COLORS.REMOVE_RED};
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 2px;
    & .icon {
      font-size: 10px;
      color: ${COLORS.REMOVE_RED};
    }
  }

  & .add_circle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    cursor: pointer;
    background-color: rgba(0, 131, 140, 0.13);
  }
  & .add_icon {
    color: ${COLORS.BTN_GREEN};
    font-size: 18px;
  }

  & .pending_mediator {
    font-size: 10px;
    margin-top: 2px;
    font-family: ${theme.fonts.primaryFontSemiBold};
    color: ${COLORS.WARNING};
  }
`;

export const CaseManagerCardData = styled.div`
  width: 295px;
  height: 77px;
  border-radius: 7px;
  border: solid 0.5px #e1e3ee;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 10px 18px 16px;
`;

export const CaseManagerTitle = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 10px;
  line-height: 1.7;
  color: ${COLORS.INPUT_LABEL};
`;
export const Flex = styled.div`
  display: flex;
  align-items: center;
`;
export const CaseManagerIcon = styled.div`
  width: 39px;
  height: 39px;
  border-radius: 50%;
  background-color: rgba(0, 131, 140, 0.13);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CaseManagerName = styled.div`
  font-size: 12px;
  font-family: ${theme.fonts.primaryFontSemiBold};
  color: ${COLORS.COLOR_DARK};
  padding: 0 6px;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
`;
export const CaseManagerProfile = styled.img`
  border-radius: 50%;
  width: 25px;
  height: 25px;
  background-color: #efefef;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const DropDown = styled.div`
  width: 24px;
  height: 24px;
  margin: 0 17px 0 25px;
  border-radius: 50%;
  background-color: ${COLORS.TABLE_GREY_CELL};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const Icon = styled.img`
  width: 11px;
  height: 6px;
  object-fit: "contain";
  transform: rotate(${({ expanded }) => (expanded ? 180 : 0)}deg);
  transition: 5sec;
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  & div {
    margin-left: 35px;
  }
`;

export const Heading = styled.span`
  margin-bottom: 31px;
  font-family: ${theme.fonts.primaryFontExtraBold};
  font-size: ${(props) => (props.size ? props.size : "14px")};
  color: ${COLORS.COLOR_DARK};
  text-transform: uppercase;
  cursor: pointer;
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 67px;
  width: 100%;
  cursor: default;
  & .title-container {
    display: flex;
    cursor: text;
    align-items: center;
    & div:last-child {
      margin-left: 15px;
    }
  }
`;
