import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import _ from "lodash";
import styled from "styled-components";
import { sidebar_options } from "./constants";
import { navigate } from "@reach/router";
import Collapse from "@material-ui/core/Collapse";

const drawerWidth = 273;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    [theme.breakpoints.down("xs")]: {
      position: "absolute",
    },
  },
  drawerContainer: {
    overflow: "auto",
    backgroundColor: "#293461",
    height: "100%",
  },
  inactiveListItem: {
    padding: "0px",
    paddingLeft: 42,
    height: 62,
    borderBottom: "solid 0.3px rgba(255, 255, 255, 0.12)",
    "& span": {
      transition: "250ms",
    },
    "&:hover": {
      backgroundColor: "#333d68",
      "& span": {
        color: "#fff",
      },
    },
  },
  activeListItem: {
    padding: "0px",
    paddingLeft: 42,
    height: 62,
    borderBottom: "solid 0.3px rgba(255, 255, 255, 0.12)",
    "& span": {
      transition: "250ms",
    },
    "&:hover": {
      backgroundColor: "#333d68",
      "& span": {
        color: "#fff",
      },
    },
  },
  nested: {
    margin: "0 0 15px 0",
    marginLeft: "50px",
    width: "200px !important",
    height: "38px",
    borderRadius: "5px",
    transition: "300ms",
    boxSizing: "borderbox",
    "& span": {
      transition: "250ms",
      fontSize: "14px",
    },
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.05)",
      borderLeft: "3px solid #fff",
      // borderRadius: '0 5px 5px 0'
      "& span": {
        color: "#fff",
      },
    },
  },
  inactiveNested: {
    backgroundColor: "transparent",
  },
  activeNested: {
    backgroundColor: "rgba(255, 255, 255, 0.05)",
    borderLeft: "3px solid #fff",
    // borderRadius: '0 5px 5px 0'
  },
}));

// const handleRoute = (route) => {
//   switch (route) {
//     case "home":
//       navigate("/dashboard");
//       break;
//     case "my_cases":
//       navigate("/dashboard/cases");
//       break;
//     case "my_parties":
//       navigate("/dashboard/verify-parties");
//       break;
//     case "my_profile":
//       navigate("/dashboard/profile");
//       break;
//     case "payments":
//       navigate("/dashboard/payments");
//       break;
//     case "help":
//       navigate("/dashboard/help");
//       break;
//     default:
//       break;
//   }
// };

export default function Sidebar({ selectedItem, selectedSubItem }) {
  const classes = useStyles();
  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <Toolbar />
      <div className={classes.drawerContainer}>
        <List>
          {sidebar_options.map((data, index) => (
            <React.Fragment key={index}>
              <ListItem
                className={
                  data.title === selectedItem
                    ? classes.activeListItem
                    : classes.inactiveListItem
                }
                style={
                  data.hasOwnProperty("listItems") ? { borderBottom: 0 } : {}
                }
                button
                onClick={() => navigate(data.path)}
                key={data.title}
              >
                <ListItemIcon>
                  <Icon
                    src={
                      data.title === selectedItem ? data.icon : data.inactive
                    }
                  />
                </ListItemIcon>
                <ListItemText selected={data.title === selectedItem}>
                  {_.startCase(data.title)}
                </ListItemText>
              </ListItem>
              {data.hasOwnProperty("listItems") && (
                <Collapse in={true} timeout="auto" unmountOnExit>
                  <List
                    style={{
                      borderBottom: `solid 0.3px rgba(255, 255, 255, 0.12)`,
                    }}
                    component="div"
                    disablePadding
                  >
                    {data.listItems.map((li, index) => (
                      <ListItem
                        key={index}
                        button
                        className={`${classes.nested} ${
                          li.title === selectedSubItem
                            ? classes.activeNested
                            : classes.inactiveNested
                        }`}
                        onClick={() => navigate(li.path)}
                      >
                        <ListItemIcon>
                          <Icon
                            src={
                              li.title === selectedSubItem
                                ? li.icon
                                : li.inactive
                            }
                          />
                        </ListItemIcon>
                        <ListItemText selected={li.title === selectedSubItem}>
                          {_.startCase(li.title)}
                        </ListItemText>
                      </ListItem>
                    ))}
                  </List>
                </Collapse>
              )}
            </React.Fragment>
          ))}
        </List>
      </div>
    </Drawer>
  );
}

const ListItemText = styled.span`
  font-family: openSans-bold, sans-serif;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${({ selected }) => (selected ? "#ffffff" : "#acb1c2")};
`;

const Icon = styled.img`
  object-fit: contain;
  width: 16px;
  height: 16px;
  color: #acb1c2;
`;

const ListItemIcon = styled.div`
  margin-right: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
