import React, { useState, useRef } from "react";
import styled from "styled-components";
import theme from "../../../assets/theme";
import COLORS from "../../../assets/Colors";
import CustomTable from "../../common/CustomTable/CustomTable";
import { makeStyles } from "@material-ui/core";
import Accordion from "@material-ui/core/ExpansionPanel";
import AccordionSummary from "@material-ui/core/ExpansionPanelSummary";
import AccordionDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CaseService from "../../../services/CaseService";
import { useSnackbar } from "notistack";
import { getErrorMessage } from "../../../helpers/functions";
import FileUploadIcon from "@material-ui/icons/CloudUpload";
import { Tooltip } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import useAlert from "../../../hooks/useAlert";
import EditIcon from "@material-ui/icons/Edit";
import RecordingNameEdit from "../../common/ViewRecordingsNameEdit/index";
import AWSService from "../../../services/AWSService";
import useLoader from "../../../hooks/useLoader";
import DocumentUploadModal from "../../common/DocumentUploadModal/DocumentTranscriptUpload";
import DocumentUploadUpdate from "../../common/DocumentUploadModal/DocumentTranscriptUpdate";
import queryString from "query-string";


const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    backgroundColor: "#f9f9f9",
    border: "solid 0.5px #e1e3ee",
    boxShadow: "none",
    margin: "16px 0px 0px !important",
    "& .MuiExpansionPanelSummary-content": {
      margin: "27px 0 27px 24px !important"
    },
    "& .MuiIconButton-root": {
      padding: 0,
      marginRight: 15,
      backgroundColor: "#fff"
    },
    "& .MuiSvgIcon-root": {
      fill: "#00838c"
    }
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  }
}));

function TableWithAccordion({ title, id, setState }) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [docUploadModal, setDocUploadModal] = useState(false);
  const fileRef = useRef();
  const [url, setUrl] = useState("");
  const { setLoader } = useLoader();
  const [file] = useState([]);
  const [recordData, setRecordData] = useState();
  const [docUploadModalUpdate, setDocUploadModalUpdate] = useState(false);
  const { hideAlert, showAlert } = useAlert();
  const [editName, setEditName] = useState(false);
  const [editNameRecord, setEditNameRecord] = useState(false);
  const [result, setResult] = useState({});
  const MTRef = useRef();

  async function getViewMeeting(id, query) {
    try {
      const response = await CaseService.getMeetingVideo(id, query);
      return { ...response };
    } catch (error) {
      throw error;
    }
  }

  async function s3Upload(file) {
    if (file) {
      try {
        const errorMessage = "Invalid File Type";
        if (
          file?.type !== "application/pdf" &&
          file?.type !== "application/msword" &&
          file?.type !==
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        )
          throw errorMessage;
        setLoader({ state: true, message: `Uploading file ${file.name} ...` });
        const payload = {
          key: file.name
        };
        const s3Response = await AWSService.getS3URL(payload);
        if (s3Response.url) {
          const res = await AWSService.uploadToS3(s3Response.url, file);
          setUrl(res);
        }
      } catch (error) {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, { variant: "error" });
      } finally {
        setLoader({ state: false });
      }
    }
  }

  async function addTranscriptForCase(checked, type) {
    let meetingId = recordData.meetingID;
    let recordId = recordData.recordID;
    try {
      setLoader({ state: true, message: `Submitting file...` });
      const postData = {
        meetingId: meetingId,
        recordId: recordId,
        transcriptDocUrl: url
      };
      const upload_response = await CaseService.addTranscript(postData);
      if (upload_response) {
        enqueueSnackbar("Successfully Uploaded", { variant: "success" });
      }
      setUrl("");
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error"
      });
    } finally {
      setLoader({ state: false });
      MTRef.current.onQueryChange("");
      setDocUploadModal(false);
    }
  }

  async function updateTranscriptForCase(checked, type) {
    let transcriptId = recordData.transcriptId;
    try {
      setLoader({ state: true, message: `Submitting file...` });
      const postData = {
        transcriptDocUrl: url
      };
      const upload_response = await CaseService.updateTranscript(
        postData,
        transcriptId
      );
      if (upload_response) {
        enqueueSnackbar("Successfully Uploaded", { variant: "success" });
      }
      setUrl("");
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error"
      });
    } finally {
      setLoader({ state: false });
      MTRef.current.onQueryChange("");
      setDocUploadModalUpdate(false);
    }
  }

  async function handleDeleteDoc(id) {
    try {
      setLoader({ state: true, message: "Delete Transcript..." });
      const response = await CaseService.deleteTranscriptDoc(id);
      if (response) {
        enqueueSnackbar("Transcript Document Deleted Successfully", {
          variant: "success"
        });
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error"
      });
    } finally {
      setLoader({ state: false });
      hideAlert();
      MTRef.current.onQueryChange("");
    }
  }

  const columns = [
    {
      field: "Id",
      title: "Id",
      sorting: false,
      render: rowData => (
        <div style={{ marginLeft: 35 }}>
          {(rowData?.name).replace(/\D/g, "")}
        </div>
      ),
      headerStyle: {
        padding: `12px 16px 12px 45px`
      },
      cellStyle: {
        padding: `12px 16px 12px 16px`
      }
    },
    {
      field: "RecordingUrl",
      title: "Recordings",
      sorting: false,
      render: (rowData, index) =>
        rowData?.transcriptId ? (
          <ButtonWrapperEdit>
            <HyperLink
              style={{ width: 150 }}
              onClick={() => window.open(rowData?.RecordingUrl)}
            >
              {rowData?.recordingName
                ? `${rowData?.recordingName}`
                : `${rowData?.name}`}
            </HyperLink>{" "}
            <Tooltip title="Edit Recording Name" placement="top">
              <EditIcon
                style={{
                  fill: COLORS.COLOR_DARK,
                  cursor: "pointer",
                  marginLeft: 10,
                  fontSize: 20
                }}
                onClick={() => {
                  setEditNameRecord(a => ({ ...a, state: true }));
                  setRecordData(rowData);
                }}
              />
            </Tooltip>
          </ButtonWrapperEdit>
        ) : (
          <ButtonWrapperEdit>
            <HyperLink
              style={{ width: 150 }}
              onClick={() => window.open(rowData?.RecordingUrl)}
            >
              {rowData?.recordingName
                ? `${rowData?.recordingName}`
                : `${rowData?.name}`}
            </HyperLink>{" "}
            <Tooltip title="Edit Recording Name" placement="top">
              <EditIcon
                style={{
                  fill: COLORS.COLOR_DARK,
                  cursor: "pointer",
                  marginLeft: 10,
                  fontSize: 20
                }}
                onClick={() => {
                  setEditName(a => ({ ...a, state: true }));
                  setRecordData(rowData);
                }}
              />
            </Tooltip>
          </ButtonWrapperEdit>
        ),
      headerStyle: {
        padding: `12px 16px`
      },
      cellStyle: {
        padding: `12px 16px`
      }
    },
    {
      field: "transcriptDocUrl",
      title: "Transcript",
      sorting: false,
      render: (rowData, index) =>
        rowData?.transcriptDocUrl ? (
          <HyperLink
            style={{ width: 150 }}
            onClick={() => window.open(rowData?.transcriptDocUrl)}
          >
            {rowData?.transcriptName}
          </HyperLink>
        ) : (
          <b>Transcript Not Available</b>
        ),
      headerStyle: {
        padding: `12px 16px`
      },
      cellStyle: {
        padding: `12px 16px`
      }
    },
    {
      field: "time",
      title: "Created",
      sorting: false,
      render: rowData => <b>{rowData?.time}</b>,
      headerStyle: {
        padding: `12px 16px`
      },
      cellStyle: {
        padding: `12px 16px`
      }
    },
    {
      field: "",
      title: "Action",
      sorting: false,
      render: rowData =>
        rowData?.transcriptId ? (
          <ButtonWrapper>
            <Tooltip title="Update Doc" placement="top">
              {rowData?.transcriptDocUrl ? (
                <FileUpdateIcon
                  src={require("../../../assets/images/update-file-64.png")}
                  style={{
                    cursor: "pointer",
                    marginLeft: "4px",
                    marginRight: "20px",
                    fontSize: 20
                  }}
                  onClick={() => {
                    setDocUploadModalUpdate(a => ({ ...a, state: true }));
                    setRecordData(rowData);
                  }}
                />
              ) : (
                <FileUpdateIcon
                  src={require("../../../assets/images/update-file-64.png")}
                  style={{
                    cursor: "pointer",
                    marginRight: "35px",
                    fontSize: 20
                  }}
                  onClick={() => {
                    setDocUploadModalUpdate(a => ({ ...a, state: true }));
                    setRecordData(rowData);
                  }}
                />
              )}
            </Tooltip>
            {rowData?.transcriptDocUrl ? (
              <DeleteIcon
                style={{
                  fill: COLORS.LOGOUT_RED,
                  cursor: "pointer",
                  fontSize: 20
                }}
                onClick={() =>
                  showAlert({
                    heading: "Delete Transcript Document?",
                    desc: `Are you sure you want to Delete the document?`,
                    primaryBtnText: "Delete",
                    secondaryBtnText: "Cancel",
                    clickSecondarybtn: () => hideAlert(),
                    clickPrimaryBtn: () => {
                      handleDeleteDoc(rowData.transcriptId);
                    },
                    isOpen: true,
                    onDialogClose: () => hideAlert()
                  })
                }
              />
            ) : (
              ""
            )}
          </ButtonWrapper>
        ) : (
          <Tooltip title="Upload Doc" placement="top">
            <FileUploadIcon
              style={{
                cursor: "pointer",
                fontSize: 20,
                marginLeft: 17
              }}
              onClick={() => {
                setDocUploadModal(a => ({ ...a, state: true }));
                setRecordData(rowData);
              }}
            />
          </Tooltip>
        ),
      headerStyle: {
        padding: `12px 16px`
      },
      cellStyle: {
        padding: `12px 16px`
      }
    }
  ];

  const data = query =>
    new Promise(resolve => {
      let payload = {
        page: query.page + 1,
        perPage: 10
      };
      const stringParams = `?${queryString.stringify(payload)}`;
      getViewMeeting(id, stringParams)
        .then(async result => {
          if (result) {
            resolve({
              data: result.data,
              page: result.page - 1,
              total: result.total
            });
            setResult(result);
          }
        })
        .catch(error => {
          enqueueSnackbar(getErrorMessage(error), {
            variant: "error"
          });
          setResult({
            data: [],
            lastPage: 1,
            page: 1,
            perPage: 10,
            total: 0
          });
          resolve({
            data: [],
            page: 0,
            total: 0
          });
        });
    });

  return (
    <>
      <Accordion className={classes.root}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <AccordionHeading>{title}</AccordionHeading>
        </AccordionSummary>
        <AccordionDetails>
          <TableContainer>
            <CustomTable
              pluralTitle="Recordings"
              singularTitle=""
              {...{
                columns,
                data,
                MTRef
              }}
              noToolbar
              pageSize={10}
              hidePagination={result.lastPage === 1}
              state={result}
            />
          </TableContainer>
        </AccordionDetails>
      </Accordion>
      <DocumentUploadModal
        modal={docUploadModal}
        setModal={setDocUploadModal}
        handleUpload={s3Upload}
        file={file}
        fileRef={fileRef}
        url={url}
        setUrl={setUrl}
        handleSuccess={addTranscriptForCase}
      />
      <DocumentUploadUpdate
        modal={docUploadModalUpdate}
        setModal={setDocUploadModalUpdate}
        handleUpload={s3Upload}
        file={file}
        fileRef={fileRef}
        url={url}
        setUrl={setUrl}
        handleSuccess={updateTranscriptForCase}
      />

      <RecordingNameEdit
        editName={editName}
        setEditName={setEditName}
        recordData={recordData}
        setState={setState}
        MTRef={MTRef}
      />

      <RecordingNameEdit
        editNameRecord={editNameRecord}
        setEditNameRecord={setEditNameRecord}
        recordData={recordData}
        setState={setState}
        MTRef={MTRef}
      />
    </>
  );
}
export default TableWithAccordion;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 19px 40px;
  margin-top: 15px;
  border-bottom: 1px solid ${COLORS.BORDER_GREY};
`;
export const TableContainer = styled.div`
  /* margin: 30px 29px auto 38px; */
  width: 100%;
  & .no-result {
    font-family: ${theme.fonts.primaryFontSemiBold};
    font-size: 16px;
    color: ${COLORS.COLOR_DARK};
    text-align: center;
  }
`;
export const AccordionHeading = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 16px;
  color: ${COLORS.COLOR_DARK};
`;

export const AccordionContainer = styled.div`
  padding: 0 20px;
`;

export const ImageWrapper = styled.div`
  cursor: pointer;
`;
export const HyperLink = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 14px;
  color: ${COLORS.BTN_GREEN};
  text-decoration: underline;
  margin-right: ${({ marginRight }) => (marginRight ? marginRight : "0px")};
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const StyledTitle = styled.div`
  font-family: ${theme.fonts.primaryFontBold};
  width: 200px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 65%;
  margin: 0 auto;
  @media (min-width) {
    justify-content: space-between;
  }
`;

export const ButtonWrapperEdit = styled.div`
  display: flex;
  // align-items: center;
  // justify-content: center;
  // // width: 120%;
  margin: 0 auto;
  @media (min-width) {
    justify-content: space-between;
  }
`;

export const FileUpdateIcon = styled.img`
  width: 22px;
  height: 22px;
  object-fit: contain;
  margin-right: 10px;
  cursor: pointer;
`;
