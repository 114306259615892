import React from "react";
import { Select } from "@material-ui/core";
import { StyledSelectFormControl, StyledDropdownIcon } from "./styles";
import { StyledMenuItem, CustomCheckBox } from "../FormInputs";

const CustomSelect = ({
  label,
  name,
  value = [],
  onChange,
  disabled,
  menuItemValues,
  multiple = false,
  selectedItems = [],
  white = false,
  ...props
}) => {
  function renderValue(selectValue) {
    if (multiple) {
      const selectedKeys = menuItemValues
        .filter((menuItemValue) => selectValue?.includes(menuItemValue?.value))
        .map((menuItemValues) => menuItemValues?.label);
      return selectedKeys?.join(", ");
    } else {
      const selectedKey = menuItemValues.find(
        (menuItemValue) => menuItemValue.value === selectValue
      );
      return selectedKey?.label;
    }
  }

  return (
    <StyledSelectFormControl
      className={white ? "input-white" : ""}
      variant="outlined"
    >
      <Select
        {...{ multiple, name, value, onChange, renderValue, disabled }}
        {...props}
        IconComponent={() => (
          <StyledDropdownIcon
            alt="select"
            src={require("../../../assets/images/dropdownIcon.svg")}
          />
        )}
      >
        {menuItemValues.map((item, idx) => (
          <StyledMenuItem key={idx} value={item.value} disabled={item.disabled}>
            {multiple && (
              <CustomCheckBox checked={selectedItems.includes(item.value)} />
            )}
            {item.label}
          </StyledMenuItem>
        ))}
      </Select>
    </StyledSelectFormControl>
  );
};

export default CustomSelect;
