import React, { useState, useEffect } from "react";
import styled from "styled-components";
import theme from "../../../assets/theme";
import COLORS from "../../../assets/Colors";
import CustomTable from "../../common/CustomTable/CustomTable";
import { makeStyles, Tooltip } from "@material-ui/core";
import Accordion from "@material-ui/core/ExpansionPanel";
import AccordionSummary from "@material-ui/core/ExpansionPanelSummary";
import AccordionDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import FormField from "../../common/FormField/FormField";
import { PrimaryCTAButton } from "../../common/Buttons";
import useLoader from "../../../hooks/useLoader";
import { useSnackbar } from "notistack";
import { getErrorMessage } from "../../../helpers/functions";
import { Form, Formik } from "formik";
import CaseService from "../../../services/CaseService";


const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: "#f9f9f9",
    border: "solid 0.5px #e1e3ee",
    boxShadow: "none",
    margin: "16px 0px 0px !important",
    "& .MuiExpansionPanelSummary-content": {
      margin: "27px 0 27px 24px !important",
    },
    "& .MuiIconButton-root": {
      padding: 0,
      marginRight: 15,
      backgroundColor: "#fff",
    },
    "& .MuiSvgIcon-root": {
      fill: "#00838c",
    },
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

function TableWithAccordion({ MTRef, displayAuthUrl, caseId, setOpenDrawer }) {
  const classes = useStyles();
  const themes = useTheme();
  const isBigScreen = useMediaQuery(themes.breakpoints.up(780));
  const { setLoader } = useLoader();
  const { enqueueSnackbar } = useSnackbar();
  const [caseDetails, setCaseDetails] = useState();


  useEffect(() => {
    async function getCase(caseId) {
      try {
        setLoader({ state: true });
        const response = await CaseService.getCase(caseId);
        if (response) {
          setCaseDetails(response);
        }
      } catch (error) {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, {
          variant: "error",
        });
      } finally {
        setLoader({ state: false });
      }
    }
    if (caseId) {
      getCase(caseId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caseId]);

  async function onFormSubmit({ oldEmail, newEmail }) {
    try {
      setLoader({ state: true, message: "Submitting..." });
      const payload = {
        oldEmail,
        newEmail,
      };
      const response = await CaseService.updateCaseEmail(caseId, payload);
      if (response) {
        enqueueSnackbar(response.message, {
          variant: "success",
        });
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
      setOpenDrawer(false);
    }
  }

  const [inputs] = useState([
    {
      name: "newEmail",
      label: "Email",
      type: "text",
      required: true,
    },
  ]);

  const columns = [
    {
      field: "name",
      title: "Agent Name",
      sorting: false,
    },
    {
      field: "email",
      title: "Email",
      sorting: false,
      render: (rowData) => updateCaseEmail(rowData),
      // render: (rowData) => (
      //   <a href={`mailto:${rowData.email}`}>{rowData.email}</a>
      // ),
    },
    {
      field: "mobile",
      title: "Phone",
      sorting: false,
    },
  ];
  if (displayAuthUrl) {
    columns.push({
      field: "user",
      title: "Authorization Letter",
      sorting: false,
      render: (rowData) =>
        rowData.authorizationLetterUrl ? (
          <Tooltip
            title={
              rowData?.authorizationLetterUrl?.split("/")[
                rowData?.authorizationLetterUrl?.split("/").length - 1
              ]
            }
            placement="top"
          >
            <HyperLink
              onClick={() => window.open(rowData.authorizationLetterUrl)}
              style={{
                maxWidth: isBigScreen ? "150px" : "auto",
              }}
            >
              {rowData?.authorizationLetterUrl?.split("/").length > 0
                ? decodeURI(
                    rowData?.authorizationLetterUrl?.split("/")[
                      rowData?.authorizationLetterUrl?.split("/").length - 1
                    ]
                  )
                : ""}
            </HyperLink>
          </Tooltip>
        ) : (
          <p>-</p>
        ),
    });
  }

  const updateCaseEmail = (rowData) => {
    return (
      <>
        <Formik
          initialValues={{
            name: rowData?.name || "",
            email: rowData?.email || "",
            mobile: rowData?.mobile || "",
            address: rowData?.address || "",
            respondentKind: rowData?.respondentKind || "",
            oldEmail: rowData?.email || "",
            newEmail: rowData?.email || "",
          }}
          enableReinitialize
          validateOnBlur
          validateOnChange
          onSubmit={onFormSubmit}
        >
          {({
            values,
            handleChange,
            errors,
            handleSubmit,
            touched,
            setFieldValue,
            setFieldError,
            handleBlur,
            isValid,
          }) => (
            <>
              <Form className="w100p" onSubmit={handleSubmit}>
                {inputs.map((input, index) => {
                  return (
                    <FormField
                      key={index}
                      {...{
                        touched,
                        errors,
                        input,
                        values,
                        handleChange,
                        handleBlur,
                        setFieldValue,
                      }}
                    />
                  );
                })}
                <ButtonContainer>
                  <PrimaryCTAButton
                    disabled={!isValid || !values?.newEmail}
                    onClick={handleSubmit}
                    style={{ width: 275 }}
                  >
                    Save
                  </PrimaryCTAButton>
                </ButtonContainer>
              </Form>
            </>
          )}
        </Formik>
      </>
    );
  };

  return (
    <Accordion className={classes.root}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <AccordionHeading>Respondent Agent Details</AccordionHeading>
      </AccordionSummary>
      <AccordionDetails>
        <TableContainer>
          {caseDetails?.respondentParties?.length ? (
            <CustomTable
              pluralTitle="Respondent"
              singularTitle="Respondent"
              {...{
                columns,
                MTRef,
              }}
              data={caseDetails?.respondentParties}
              noToolbar
              pageSize={1000}
              hidePagination={true}
            />
          ) : (
            <div className="no-result">No Respondent Found</div>
          )}
        </TableContainer>
      </AccordionDetails>
    </Accordion>
  );
}

export default TableWithAccordion;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 19px 40px;
  margin-top: 15px;
  border-bottom: 1px solid ${COLORS.BORDER_GREY};
`;
export const TableContainer = styled.div`
  /* margin: 30px 29px auto 38px; */
  width: 100%;
  & .no-result {
    font-family: ${theme.fonts.primaryFontSemiBold};
    font-size: 16px;
    color: ${COLORS.COLOR_DARK};
    text-align: center;
  }
`;
export const AccordionHeading = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 16px;
  color: ${COLORS.COLOR_DARK};
`;

export const AccordionContainer = styled.div`
  padding: 0 20px;
`;

export const ImageWrapper = styled.div`
  cursor: pointer;
`;
export const HyperLink = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 14px;
  color: ${COLORS.BTN_GREEN};
  text-decoration: underline;
  margin-right: ${({ marginRight }) => (marginRight ? marginRight : "0px")};
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const StyledTitle = styled.div`
  font-family: ${theme.fonts.primaryFontBold};
  width: 200px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
`;

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  @media (min-width: 780px) {
    flex-direction: column;
    align-items: start;
  }
`;
