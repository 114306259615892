import labels from "../../../helpers/labels.json";

export const sidebar_options = [
  {
    icon: require("../../../assets/images/hamMenu.svg"),
    inactive: require("../../../assets/images/hamMenuInactive.svg"),
    title: labels.my_tasks,
    path: "/dashboard/",
    listItems: [
      {
        icon: require("../../../assets/images/pendingInactive.svg"),
        inactive: require("../../../assets/images/pending.svg"),
        title: labels.pending_actions,
        path: "/dashboard/",
      },
      {
        icon: require("../../../assets/images/invoice.svg"),
        inactive: require("../../../assets/images/invoiceInactive.svg"),
        title: labels.proforma_invoice,
        path: "/dashboard/proforma-invoice",
      },
      {
        icon: require("../../../assets/images/upload.svg"),
        inactive: require("../../../assets/images/uploadInactive.svg"),
        title: labels.upload,
        path: "/dashboard/uploads",
      },
      {
        icon: require("../../../assets/images/tick.svg"),
        inactive: require("../../../assets/images/tickInactive.svg"),
        title: labels.verify,
        path: "/dashboard/verify-parties",
      },
    ],
  },
  {
    icon: require("../../../assets/images/cases.svg"),
    inactive: require("../../../assets/images/casesInactive.svg"),
    title: labels.cases,
    path: "/dashboard/mediation",
    listItems: [
      // {
      //   icon: require("../../../assets/images/negotiation-active.svg"),
      //   inactive: require("../../../assets/images/negotiation-inactive.svg"),
      //   title: labels.negotiation,
      //   path: "/dashboard/negotiation",
      // },
      {
        icon: require("../../../assets/images/mediation-active.svg"),
        inactive: require("../../../assets/images/mediation-inactive.svg"),
        title: labels.mediation,
        path: "/dashboard/mediation",
      },
      {
        icon: require("../../../assets/images/mediation-active.svg"),
        inactive: require("../../../assets/images/mediation-inactive.svg"),
        title: labels.arbitration,
        path: "/dashboard/arbitration",
      },
      {
        icon: require("../../../assets/images/bulkUploads.svg"),
        inactive: require("../../../assets/images/bulkUploadsInactive.png"),
        title: labels.bulk_uploads,
        path: "/dashboard/bulk-uploads",
      },
    ],
  },
  {
    icon: require("../../../assets/images/my_parties.svg"),
    inactive: require("../../../assets/images/partiesInactive.svg"),
    title: labels.parties,
    path: "/dashboard/parties",
  },
  {
    icon: require("../../../assets/images/profiles.svg"),
    inactive: require("../../../assets/images/profilesInactive.svg"),
    title: labels.profiles,
    path: "/dashboard/profiles",
  },
  {
    icon: require("../../../assets/images/config.png"),
    inactive: require("../../../assets/images/configInactive.png"),
    title: labels.meeting_details,
    path: "/dashboard/recordings",
  },
  // {
  //   icon: require("../../../assets/images/config.png"),
  //   inactive: require("../../../assets/images/configInactive.png"),
  //   title: labels.configurations,
  //   path: "/dashboard/configurations",
  // },
];
