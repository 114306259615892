import React, { useState, useEffect, useRef } from "react";
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  makeStyles,
  Menu,
  MenuItem,
  CircularProgress
} from "@material-ui/core";
import StatusBadge from "../../../common/StatusBadge";
import { Images } from "../../../../assets/images";
import { DropDown, Icon, Actions, Heading, Header } from "../styles";
import styled, { css } from "styled-components";
import COLORS from "../../../../assets/Colors";
import theme from "../../../../assets/theme";
import _ from "lodash";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import moment from "moment";
import { PrimaryCTAButton } from "../../../common/Buttons";
import CaseService from "../../../../services/CaseService";
import useLoader from "../../../../hooks/useLoader";
import { useSnackbar } from "notistack";
import { getErrorMessage, parseTimeStamp } from "../../../../helpers/functions";
import useUser from "../../../../hooks/useUser";
import InfiniteScroll from "react-infinite-scroller";
import AddZoomLinkModal from "../../../common/AddZoomLinkModal";
import { DangerBtn } from "../../../../styles/component/style";
import AlertDialog from "../../../common/Alert";

const ESCAPE_HTML_REGEX = /(<([^>]+)>)/gi;

var icons = ReactQuill.Quill.import("ui/icons");
icons[
  "bold"
] = `<img src=${require("../../../../assets/images/editor/bold.svg")} aria-hidden="true" />`;
icons[
  "italic"
] = `<img src=${require("../../../../assets/images/editor/italics.svg")} aria-hidden="true" />`;
icons[
  "underline"
] = `<img src=${require("../../../../assets/images/editor/underline.svg")} aria-hidden="true" />`;
icons[
  "blockquote"
] = `<img src=${require("../../../../assets/images/editor/blockQuote.svg")} aria-hidden="true" />`;
icons["align"][
  ""
] = `<img src=${require("../../../../assets/images/editor/leftAlign.svg")} aria-hidden="true" />`;
icons["align"][
  "center"
] = `<img src=${require("../../../../assets/images/editor/centerAlign.svg")} aria-hidden="true" />`;
icons["align"][
  "right"
] = `<img src=${require("../../../../assets/images/editor/rightAlign.svg")} aria-hidden="true" />`;

const modules = {
  toolbar: [
    [
      { align: "" },
      { align: "center" },
      { align: "right" },
      "bold",
      "italic",
      "underline",
      "blockquote"
    ]
  ]
};

const useStyles = makeStyles(() => ({
  root: {
    boxShadow: "none",
    border: `solid 0.5px #e1e3ee`
  },
  content: {
    margin: `0 !important`,
    "& .MuiExpansionPanelSummary-content.Mui-expanded": {
      margin: `0 !important`
    }
  },
  details: {
    display: "flex",
    flexDirection: "column",
    padding: `0px !important`
  },
  paper: {
    boxShadow: "none",
    border: "solid 0.5px #e1e3ee"
  },
  menuRoot: {
    fontFamily: theme.fonts.primaryFontSemiBold,
    fontSize: 12,
    color: COLORS.COLOR_DARK
  }
}));

const roleColors = {
  mediator: {
    color: "#293461",
    "background-color": "rgba(246, 180, 54, 0.3)"
  },
  caseManager: {
    color: "#f4f6ff",
    "background-color": "#293461"
  },
  respondent: {
    color: "#293461",
    "background-color": "rgba(255, 60, 60, 0.3)"
  },
  claimant: {
    color: "#293461",
    "background-color": "rgba(0, 131, 140, 0.3)"
  },
  other: {
    color: "#293461",
    "background-color": "rgba(0, 131, 140, 0.3)"
  }
};

export default function CommentBox({
  title,
  status,
  expand,
  errorText,
  comments,
  caseId,
  setComments,
  disabled = false,
  hideAddComment = true,
  hasMediator = false,
  partyList = [],
  kind = "Mediator"
}) {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(
    comments?.length > 0 || hideAddComment
  );
  const [trigggerStateChange, setTrigggerStateChange] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const chatContainerRef = useRef();
  const [comment, setComment] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const { setLoader } = useLoader();
  const { userObject } = useUser();
  const [modal, setModal] = useState({ state: false });
  const [open, setOpen] = useState(false);
  const [dialogData, setDialogData] = useState(false);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (trigggerStateChange) {
      setTrigggerStateChange(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigggerStateChange]);

  useEffect(() => {
    if (chatContainerRef?.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [chatContainerRef]);

  async function send(visibilityType = "all", data, isFromZoom, partyId) {
    try {
      setLoader({ state: true, message: "Submitting response..." });
      let postbody = {
        content: isFromZoom ? "" : comment,
        visibilityType,
        visibilityPartyId: partyId
      };
      if (isFromZoom) {
        postbody = { ...postbody, ...data };
      }
      const response = await CaseService.sendComment(caseId, postbody);
      if (response?.currentComment) {
        setComments(c => [
          { ...response?.currentComment, owner: userObject },
          ...c
        ]);
        enqueueSnackbar(response?.message, {
          variant: "success"
        });
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error"
      });
    } finally {
      setLoader({ state: false });
    }
  }

  async function updateZoomLink(visibilityType = "all", data, commentData) {
    try {
      setLoader({ state: true, message: "Updating Video Call Link..." });
      let postbody = {
        ...data,
        content: "",
        visibilityType
      };
      const response = await CaseService.updateComment(
        caseId,
        postbody,
        commentData?.id
      );
      if (response?.currentComment) {
        let existingcmd = [...comments];
        existingcmd = existingcmd.map(el => {
          let comment = {};
          if (el?.id === response?.currentComment?.id) {
            comment = { ...el, ...response?.currentComment };
          } else {
            comment = el;
          }
          return comment;
        });
        setComments(existingcmd);
        setModal({});
        enqueueSnackbar(response?.message, {
          variant: "success"
        });
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error"
      });
    } finally {
      setLoader({ state: false });
    }
  }

  // const deleteVideoCall = async (commentId) => {
  //   try {
  //     setLoader({ state: true, message: "Deleting Video Call Link..." });
  //     const response = await CaseService.deleteComment(caseId, commentId);
  //     if (response?.message) {
  //       let existingcmd = [...comments];
  //       existingcmd = existingcmd.filter((el) => el.id !== commentId);
  //       setComments(existingcmd);
  //       enqueueSnackbar(response?.message, {
  //         variant: "success",
  //       });
  //     }
  //   } catch (error) {
  //     const message = getErrorMessage(error);
  //     enqueueSnackbar(message, {
  //       variant: "error",
  //     });
  //   } finally {
  //     setLoader({ state: false });
  //     setOpen(false);
  //   }
  // };

  const DisableVideoCall = comment => {
    const postData = {
      zoomLink: {
        ...comment?.zoomLink,
        disable: true
      }
    };
    updateZoomLink("all", postData, comment);
  };

  /**
   * @description Function to open the delete warnging message
   * @param {*} id
   */
  const disableWarning = comment => {
    setOpen(true);
    setDialogData({
      primaryBtnText: "Proceed",
      secondaryBtnText: "Cancel",
      clickPrimaryBtn: () => DisableVideoCall(comment),
      clickSecondarybtn: () => setOpen(false),
      desc: `Are you sure you want to disable this meeting? This operation cannot be undone.`,
      heading: "Disable Meeting",
      descriptionTextStyle: {
        margin: "0px 60px 50px",
        textAlign: "center",
        fontFamily: theme.fonts.primaryFontSemiBold
      }
    });
  };

  // useEffect(() => {}, [currentPage]);

  const loadMore = () => {
    console.log("load more");
  };

  return (
    <>
      <ExpansionPanel className={classes.root} expanded={expanded}>
        <ExpansionPanelSummary
          classes={{ content: classes.content }}
          onClick={() => setExpanded(e => !e)}
        >
          <Header>
            <div className="title-container">
              <Heading
                style={{
                  marginBottom: "unset",
                  marginLeft: "21px",
                  textTransform: "none",
                  fontWeight: "800",
                  fontSize: "18px"
                }}
              >
                {title}
              </Heading>
              {status && (
                <StatusBadge
                  text={
                    status === "success"
                      ? "Negotiation Successful"
                      : errorText
                      ? errorText
                      : "Negotiation In Progress"
                  }
                  status={status}
                />
              )}
            </div>
            <Actions>
              <DropDown>
                <Icon
                  src={Images.dropDownArrow}
                  alt="dropdown"
                  expanded={expanded}
                />
              </DropDown>
            </Actions>
          </Header>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.details}>
          {comments?.length > 0 || hideAddComment ? (
            <div>
              <div
                ref={chatContainerRef}
                style={{
                  padding: `0 20px 20px`,
                  maxHeight: 400,
                  overflow: "auto"
                }}
              >
                <InfiniteScroll
                  pageStart={0}
                  loadMore={loadMore}
                  initialLoad={false}
                  hasMore={false}
                  useWindow={false}
                  threshold={50}
                  isReverse={true}
                  loader={
                    <Loader key={0}>
                      <CircularProgress />
                    </Loader>
                  }
                >
                  {" "}
                  {comments
                    ?.sort(
                      (a, b) =>
                        new Date(moment(a?.created_at)) -
                        new Date(moment(b?.created_at))
                    )
                    ?.map(comment => (
                      <CommentContainer
                        isOwner={
                          comment?.ownerId === userObject?.id &&
                          comment?.ownerType === "caseManager"
                        }
                      >
                        <div id="flex">
                          <div className="flex">
                            <CommentUserName>
                              {comment?.owner?.name}
                            </CommentUserName>
                            <SenderRole
                              style={{ marginLeft: 15 }}
                              role={
                                roleColors[
                                  comment?.ownerType === "agent"
                                    ? comment?.ownerRole
                                    : comment?.ownerType
                                ]
                              }
                            >
                              {_.startCase(
                                comment?.ownerType === "agent"
                                  ? comment?.ownerRole
                                  : comment?.ownerType === "mediator"
                                  ? kind
                                  : comment?.ownerType
                              )}
                            </SenderRole>
                          </div>
                          <PrivateContainer>
                            {comment?.visibilityType !== "all" && (
                              <PrivateContainer>
                                <LockIcon
                                  src={require("../../../../assets/images/lock.svg")}
                                />
                                <PrivateText>
                                  Private to{" "}
                                  {comment?.visibilityPartyId
                                    ? partyList?.find(
                                        el =>
                                          el?.partyId ===
                                          comment?.visibilityPartyId
                                      )?.party?.name ||
                                      _.startCase(comment?.visibilityType)
                                    : comment?.visibilityType === "mediator"
                                    ? kind
                                    : _.startCase(comment?.visibilityType)}
                                </PrivateText>
                              </PrivateContainer>
                            )}
                          </PrivateContainer>
                        </div>
                        <CommentTimestamp>
                          {moment(parseTimeStamp(comment?.created_at)).format(
                            "MMMM D, YYYY"
                          ) +
                            " at " +
                            moment(parseTimeStamp(comment?.created_at)).format(
                              "hh:mm A"
                            )}
                        </CommentTimestamp>
                        {comment?.content && (
                          <CommentContent
                            dangerouslySetInnerHTML={{
                              __html: comment?.content
                            }}
                          />
                        )}
                        {!_.isEmpty(comment?.zoomLink) && (
                          <div>
                            <div className="data-container">
                              <div className="data-field">
                                <div className="label">Date</div>
                                <div className="value">
                                  {moment(
                                    new Date(comment?.zoomLink?.date)
                                  ).format("DD-MM-YYYY")}
                                </div>
                              </div>
                              <div
                                className="data-field"
                                style={{ marginLeft: 30 }}
                              >
                                <div className="label">Time</div>
                                <div className="value">
                                  {moment(
                                    new Date(comment?.zoomLink?.date)
                                  ).format("hh:mm A")}{" "}
                                  (IST)
                                </div>
                              </div>
                            </div>
                            <div className="action_container">
                              <div className="action-btn">
                                <PrimaryCTAButton
                                  disabled={
                                    moment(new Date()).isAfter(
                                      moment(
                                        new Date(comment?.zoomLink?.date)
                                      ).add(1, "day")
                                    ) || comment?.zoomLink?.disable
                                  }
                                  onClick={() =>
                                    window.open(
                                      comment?.zoomLink?.url,
                                      "_blank"
                                    )
                                  }
                                >
                                  Join
                                </PrimaryCTAButton>
                              </div>
                              {!hideAddComment && (
                                <div
                                  className="action-btn"
                                  style={{ margin: "0px 30px" }}
                                >
                                  <PrimaryCTAButton
                                    disabled={comment?.zoomLink?.disable}
                                    onClick={() =>
                                      setModal({
                                        state: true,
                                        data: comment
                                      })
                                    }
                                  >
                                    Edit
                                  </PrimaryCTAButton>
                                </div>
                              )}
                              {!hideAddComment && !comment?.zoomLink?.disable && (
                                <div className="action-btn">
                                  <DangerBtn
                                    onClick={() => disableWarning(comment)}
                                  >
                                    Disable
                                  </DangerBtn>
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      </CommentContainer>
                    ))}
                </InfiniteScroll>
              </div>
              <div style={{ padding: 20 }}>
                {!hideAddComment ? null : (
                  <div style={{ display: "flex" }}>
                    <EditorContainer>
                      <ReactQuill
                        onChange={text => setComment(text)}
                        modules={modules}
                        placeholder="Record the Proceedings…"
                        readOnly={disabled}
                      />
                    </EditorContainer>
                    <ButtonContainer>
                      <div className="vcLink">
                        <PrimaryCTAButton
                          onClick={() => setModal({ state: true })}
                        >
                          Add VC Link
                        </PrimaryCTAButton>
                      </div>

                      <PrimaryCTAButton
                        aria-controls="comment-send-menu"
                        aria-haspopup="true"
                        onClick={handleClick}
                        disabled={
                          disabled ||
                          !comment?.replace(ESCAPE_HTML_REGEX, "").trim()
                        }
                      >
                        Send
                      </PrimaryCTAButton>
                      <Menu
                        id="comment-send-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                        classes={{ paper: classes.paper }}
                      >
                        <Label>SEND TO</Label>
                        <MenuItem
                          className={classes.menuRoot}
                          onClick={() => send("all")}
                        >
                          All
                        </MenuItem>
                        {hasMediator ? (
                          <MenuItem
                            className={classes.menuRoot}
                            onClick={() => send("mediator")}
                          >
                            {kind}
                          </MenuItem>
                        ) : null}
                        {partyList?.map((item, index) => (
                          <MenuItem
                            key={index}
                            className={classes.menuRoot}
                            onClick={() =>
                              send(item?.partyRole, "", false, item?.partyId)
                            }
                          >
                            <ParyName title={item?.party?.name}>
                              {item?.party?.name}
                            </ParyName>
                            <MenuLabel className="partyLabel">
                              {_.capitalize(item?.partyRole)}
                            </MenuLabel>
                          </MenuItem>
                        ))}
                      </Menu>
                    </ButtonContainer>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <EmptyMsg>There are no comments yet.</EmptyMsg>
          )}
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <AddZoomLinkModal
        {...{ modal, setModal }}
        onSubmit={(data, commentData) => {
          if (commentData?.id) {
            updateZoomLink("all", data, commentData);
          } else {
            send("all", data, true);
          }
        }}
      />
      <AlertDialog isOpen={open} {...{ ...dialogData }} />
    </>
  );
}

const Label = styled.div`
  font-family: ${theme.fonts.primaryFontBold};
  font-size: 10px;
  letter-spacing: 0.33px;
  color: #acb1c2;
  padding: 0 16px 5px;
`;

const ParyName = styled.div`
  max-width: 100px;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const MenuLabel = styled.div`
  color: ${COLORS.INPUT_LABEL};
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 10px;
  display: flex;
  flex: 1;
  justify-content: flex-end;
  margin-left: 10px;
`;

const ButtonContainer = styled.div`
  width: 144px;
  margin-left: 24px;
  align-self: flex-end;
  position: relative;
  .vcLink {
    margin-bottom: 25px;
  }
`;

const CommentContainer = styled.div`
  padding: 18px;
  border-radius: 7px;
  background-color: ${props =>
    props.isOwner ? COLORS.LIGHT_BLUE : COLORS.PRIMARY_WHITE};
  border: solid 1px
    ${props => (props.isOwner ? COLORS.COLOR_DARK : COLORS.INPUT_BORDER)};
  margin-bottom: 12px;
  max-width: 730px;
  & #flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  & .data-container {
    display: flex;
    padding: 15px 0px;
    & .data-field {
      font-family: ${theme.fonts.primaryFontSemiBold};
      & .label {
        color: ${COLORS.INPUT_LABEL};
        font-size: 10px;
      }
      & .value {
        color: ${COLORS.PRIMARY_BLACK};
        margin-top: 2px;
      }
    }
  }

  & .action_container {
    display: flex;
    padding-top: 15px;
    .action-btn {
      width: 150px;
    }
  }
`;

const CommentUserName = styled.div`
  color: ${COLORS.BTN_GREEN};
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 16px;
`;

const CommentTimestamp = styled.div`
  color: #acb1c2;
  font-size: 12px;
  font-family: ${theme.fonts.primaryFontSemiBold};
  margin-top: 8px;
`;

const CommentContent = styled.div`
  font-size: 14px;
  word-break: break-word;
  color: ${COLORS.COLOR_DARK};
  font-family: ${theme.fonts.primaryFontSemiBold};
  margin-top: 18px;
  & strong,
  & bold {
    font-family: ${theme.fonts.primaryFontExtraBold};
  }
  & .ql-align-center {
    text-align: center;
  }
  & .ql-align-right {
    text-align: right;
  }
  & blockquote {
    border-left: 4px solid #ccc;
    margin-bottom: 5px;
    margin-top: 5px;
    padding-bottom: 5px;
    padding-top: 5px;
    padding-left: 16px;
    background-color: #f9f9f9;
  }
`;

const SenderRole = styled.div`
  font-size: 12px;
  font-family: ${theme.fonts.primaryFontSemiBold};
  padding: 4px 10px;
  border-radius: 4px;
  ${({ role }) =>
    role
      ? Object.keys(role).map(
          key =>
            css`
              ${key}: ${role[key]};
            `
        )
      : null}
  user-select:none;
`;

const Loader = styled.div`
  display: flex;
  justify-content: center;
`;

const EditorContainer = styled.div`
  max-width: 730px;
  width: 100%;
  & .rdw-editor-wrapper,
  .ql-editor,
  .ql-container {
    background-color: #f9f9f9;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border: none;
    font-family: ${theme.fonts.primaryFontRegular};
  }
  .ql-editor {
    padding: 16px 16px;
    height: 80px;
  }
  & .rdw-editor-toolbar,
  .ql-toolbar {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-color: #f4f6ff;
    border: none;
    & button {
      padding: 0;
    }
  }
  & .rdw-option-wrapper {
    background-color: transparent;
    border: 1px solid transparent;
    &:hover {
      box-shadow: none;
    }
  }
  & .ql-active {
    box-shadow: none;
    border: 1px solid rgb(200, 210, 220) !important;
  }
  & .rdw-editor-main {
    padding: 0 17px 10px;
    font-family: ${theme.fonts.primaryFontRegular};
  }
  & .public-DraftEditorPlaceholder-root,
  & .quill > .ql-container > .ql-editor.ql-blank::before {
    font-family: ${theme.fonts.primaryFontSemiBold};
    font-size: 14px;
    color: #acb1c2;
    font-style: normal;
  }
  & .blockquote .rdw-option-wrapper {
    content: "";
  }
  & .ql-toolbar.ql-snow {
    padding: 12px 12px 16px;
    .ql-formats button {
      margin-right: 60px;
    }
    .ql-align {
      outline: none;
    }
  }
`;

const PrivateContainer = styled.div`
  display: flex;
  align-items: center;
  user-select: none;
`;

const LockIcon = styled.img`
  width: 24px;
  height: 24px;
  margin: 0 8px 0 0;
  object-fit: contain;
`;

const PrivateText = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 14px;
  color: #293461;
  margin-right: 20px;
`;

const EmptyMsg = styled.div`
  color: ${COLORS.COLOR_DARK};
  font-weight: 500;
  letter-spacing: 0.1px;
  font-size: 24px;
  margin: 20px 0px;
  font-family: ${theme.fonts.primaryFontBold};
  text-align: center;
`;
